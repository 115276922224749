import { arrayOf, bool, checkPropTypes, element, number, object, oneOfType, shape, string } from 'prop-types';

/** Validator for Portfolio.slides data object */
export const cakeInfoPropTypes = {
  recipe: string,
  story: string,
  cost: oneOfType([number, string]),
  numOfPeople: string,
  numOfPieces: oneOfType([number, string]),
  link: string,
};
export const cakeImagePropTypes = {
  index: number.isRequired,
  image: string.isRequired,
  dir: string.isRequired,
};
export const propTypes = {
  id: string.isRequired,
  tags: arrayOf(string).isRequired,
  imgLink: arrayOf(oneOfType([element, object, string])).isRequired,
  cakeInfo: shape(cakeInfoPropTypes).isRequired,
  isActive: bool,
  /* Pages */
  images: arrayOf(shape(cakeImagePropTypes)),
  /* SEO */
  title: string,
  description: string,
  keywords: arrayOf(string),
  image: element /* page image */,
};

export const validateOne = (data, element) => {
  checkPropTypes(propTypes, data, element, 'Portfolio');
  return data;
};

/** Validate array of elements. */
const validate = (array) => {
  if (!Array.isArray(array)) throw Error('Expected array of objects!');

  for (let i = 0; i < array.length; i++) {
    validateOne(array[i], `element_${i}`);
  }

  return array;
};

export default validate;
