import React from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './styles';

const GalleryListItems = ({ children }) => {
  const styles = useStyles();
  return <div className={styles.wrapper}>{children}</div>;
};

GalleryListItems.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
};

export default GalleryListItems;
