import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  grid: {
    margin: '2rem auto',
    maxWidth: 935,
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
    gridGap: '1rem',
  },
  complexLink: {
    position: 'relative',
    display: 'block',
    textDecoration: 'none',
    padding: 2,
    width: '100%',
  },
  wrapper: {
    backgroundColor: 'white',
    borderRadius: 2,
    overflow: 'hidden',
    boxShadow:
      '0 13px 27px -5px rgba(50,50,93,.05), 0 8px 16px -8px rgba(0,0,0,.05), 0 -6px 16px -6px rgba(0,0,0,.015)',
  },
  inner: {
    padding: 3,
    backgroundColor: 'inherit',
  },
  userName: {
    ...theme.typography.normalGrayText,
    margin: 0,
    padding: 0,
  },
  flex: {
    display: 'flex',
  },
  info: {
    marginRight: '1rem',
    alignItems: 'center',
  },
  likes: {
    margin: '0 1rem',
    padding: 0,
    ...theme.typography.normalGrayText,
  },
  comments: {
    margin: '0 1rem',
    padding: 0,
    ...theme.typography.normalGrayText,
  },
}));
