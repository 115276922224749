import Img0 from '@assets/cakes/094_spiderman/img_4434.webp';
import Img1 from '@assets/cakes/094_spiderman/img_4440.webp';
import Img2 from '@assets/cakes/094_spiderman/img_4461.webp';
import Img3 from '@assets/cakes/094_spiderman/img_4467.webp';

import { validateOne } from '@config/Validators/cake.validator';
import data from './data.json';
// import t from '@unicat/i18n/Translate';

export default validateOne(
  Object.assign({}, data, {
    imgLink: [Img0, Img1, Img2, Img3],
  }),
);
