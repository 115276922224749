import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: '0 1rem',
    columnCount: 3,
    columnGap: '1em',
    fontSize: '0.85em',
    borderRadius: '0.8rem',
    marginTop: theme.spacing(8),
    [theme.breakpoints.down('md')]: {
      columnCount: 2,
    },
    [theme.breakpoints.down('sm')]: {
      columnCount: 1,
    },
  },
}));
