import React from 'react';
import t from '@unicat/i18n/Translate';
import data from '@config/OurAdvantages/index.json';
import { useStyles } from './styles';
import clsx from 'clsx';
import { Hidden } from '@material-ui/core';
import Carousel from '@unicat/UI/Carousel';
import { anchors } from '@config/headerRoutes';

const OurAdvantages = () => {
  const classes = useStyles();

  return (
    <>
      <section id={anchors.OurAdvantage} className={classes.advantagesWrapper}>
        <div className={classes.content}>
          <div className={clsx(classes.titleWrapper)}>
            <h3 className={clsx(classes.titleWr)}>{t('Our advantages')}</h3>
          </div>
          <div className={classes.contentInner}>
            <Hidden xsDown>
              <div className={classes.blockWrapper}>
                {data.advantages.map((item) => (
                  <div className={classes.block} key={item.title}>
                    <div className={classes.title}>{t(item.title)}</div>
                    <div className={classes.text}>{t(item.text)}</div>
                  </div>
                ))}
              </div>
            </Hidden>
            <Hidden smUp>
              <Carousel data={data.advantages} />
            </Hidden>
          </div>
        </div>
      </section>
    </>
  );
};

export default OurAdvantages;
