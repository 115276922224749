import React from 'react';
import PropTypes from 'prop-types';
import data from '@config/OurAdvantages';
import { useStyles } from '@unicat/OurAdvantages/styles';
import { CarouselProvider, Slider } from 'pure-react-carousel';
import SlideContainer from '@unicat/UI/SlideContainer';
import CarouselArrows from '@unicat/UI/CarouselArrows';
import clsx from 'clsx';

const Carousel = ({ data = data.advantages }) => {
  const classes = useStyles();

  return (
    <CarouselProvider naturalSlideHeight={6} naturalSlideWidth={10} totalSlides={data?.length ?? 0} infinite={true}>
      {/* Slider */}
      <Slider className={clsx([classes.sliderControl])}>
        {data.map((item, index) => (
          <SlideContainer text={item.text} title={item.title} key={item.title} index={index} />
        ))}
      </Slider>
      <CarouselArrows bgColor="white" />
    </CarouselProvider>
  );
};

Carousel.defaultProps = {
  data: data.advantages,
};

Carousel.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Carousel;
