import React from 'react';
import PropTypes from 'prop-types';

class CrossFadeImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      topSrc: props.src,
      bottomOpacity: 0,
      bottomSrc: props.src,
    };
    this.timeout = undefined;
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.src !== this.props.src) {
      this.setState({ bottomSrc: '', topSrc: '' }, () =>
        this.setState({ bottomSrc: prevProps.src, topSrc: this.props.src, bottomOpacity: 0.99 }, () => {
          if (!this.timeout) clearTimeout(this.timeout);
          this.timeout = setTimeout(() => this.setState({ bottomOpacity: 0 }), 20);
        }),
      );
    }
  }

  render() {
    const { containerClass, duration, timingFunction, delay, style, alt } = this.props;
    const { topSrc, bottomOpacity, bottomSrc } = this.state;

    return (
      <div className={containerClass} style={{ ...defaultStyle, ...{ position: 'relative' } }}>
        {topSrc && <img style={{ ...defaultStyle, ...style, ...{ position: 'absolute' } }} src={topSrc} alt={alt} />}
        {bottomSrc && (
          <img
            style={{
              ...defaultStyle,
              ...style,
              ...{
                opacity: bottomOpacity,
                transition: `opacity ${duration / 1000}s ${timingFunction} ${delay / 1000}s`,
              },
            }}
            src={bottomSrc}
            alt={alt}
          />
        )}
      </div>
    );
  }
}

const defaultStyle = { maxWidth: '100%', maxHeight: '100%' };

CrossFadeImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  duration: PropTypes.number,
  timingFunction: PropTypes.string,
  delay: PropTypes.number,
  style: PropTypes.object,
  containerClass: PropTypes.string,
};

CrossFadeImage.defaultProps = {
  duration: 500,
  timingFunction: 'ease',
  delay: 0,
  containerClass: 'CrossFadeImage',
};

export default CrossFadeImage;
