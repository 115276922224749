import React from 'react';
import PropTypes from 'prop-types';
import { propTypes as CakesPropTypes } from '@config/Validators/cake.validator';
import clsx from 'clsx';
import t from '@unicat/i18n/Translate';
import LocalizedLink from '@unicat/i18n/LocalizedLink';

import { useStyles } from './styles';
import { ReactComponent as Arrow } from '@assets/icons/DetailsBar/arrow.svg';
import DetailParams from './DetailParams';

const DetailsBar = ({ cakeInfo: { link, numOfPieces, numOfPeople, cost }, id, hovered = false }) => {
  const classes = useStyles();

  return (
    <>
      <LocalizedLink to={link} className={clsx(classes.bar, hovered ? classes.visible : '')}>
        <div className={classes.inner}>
          <span className={classes.title}>{t(id)}</span>
          <DetailParams numOfPeople={t(numOfPeople)} cost={t(cost)} numOfPieces={t(numOfPieces)} />
        </div>
        {/* arrow link */}
        <div className={classes.arrow}>
          <Arrow />
        </div>
      </LocalizedLink>
    </>
  );
};

DetailsBar.defaultProps = {
  hovered: false,
};

DetailsBar.propTypes = {
  id: PropTypes.string.isRequired,
  cakeInfo: CakesPropTypes.cakeInfo,
  hovered: PropTypes.bool.isRequired,
};

export default DetailsBar;
