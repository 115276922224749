import React from 'react';
import PropTypes from 'prop-types';
import CrossFadeImage from '@unicat/UI/CrossfadeImage';

import { imageStyle, useStyles } from '@unicat/CakeCard/styles';
import clsx from 'clsx';

const ImageWrapper = ({ nextImage, src, hoverable = true, alt, duration = 1500 }) => {
  const styles = useStyles();
  const srcImage = nextImage ?? src;
  const image = srcImage.srcWebp ?? srcImage;

  return (
    <>
      {/*<span>{JSON.stringify(srcImage, null, 2)}</span>*/}
      <CrossFadeImage
        src={image}
        alt={alt}
        duration={duration}
        loading={'lazy'}
        style={{ ...imageStyle }}
        containerClass={clsx([styles.imageChanger, hoverable ? styles.hover : ''])}
      />
    </>
  );
};

ImageWrapper.defaultProps = {
  hoverable: true,
  duration: 1500,
};

ImageWrapper.propTypes = {
  alt: PropTypes.string.isRequired,
  src: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  nextImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  duration: PropTypes.number,
  ref: PropTypes.object,
  hovered: PropTypes.bool,
  hoverable: PropTypes.bool,
};

export default ImageWrapper;
