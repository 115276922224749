import React, { useMemo } from 'react';
import t from '@unicat/i18n/Translate';
import { useStyles } from './styles';
import BannerCarousel from '@unicat/UI/BannerCarousel';
import getDiscountSlides, { getDiscountTitle } from '@config/GetDiscount';
import { anchors } from '@config/headerRoutes';
import LaunchIcon from '@material-ui/icons/Launch';
import { Hidden } from '@material-ui/core';
import { linkButtonColorSchemas } from '@styles/styles';
import { config } from '@config';

const HomeBanner = () => {
  const {
    socialLinks: { pinterest },
  } = config;
  const classes = useStyles();
  const linkStyles = linkButtonColorSchemas();

  const mainPhraseArray =
    useMemo(() => {
      return getDiscountTitle.split(' ');
    }, [getDiscountTitle]) ?? [];

  return (
    <section id={anchors.GetDiscount} className={classes.wrapper}>
      <div className={classes.content}>
        <div className={classes.inner}>
          {/* left block */}
          <div className={classes.discount}>
            <h2 className={classes.uniqueTitle}>
              {mainPhraseArray.map((x, i) => (
                <span key={x + i}>{x} </span>
              ))}
            </h2>
            <p className={classes.text}>
              Choose the cake design from our Pinterest board that we didn`t made yet and have 5% discount
            </p>
            <a className={linkStyles.yellow} href={pinterest}>
              <p>
                {t('Check out Pinterest board ')} &nbsp;
                <LaunchIcon />
              </p>
            </a>
          </div>
          {/*  carousel block */}
          <Hidden xsDown>
            <BannerCarousel slides={getDiscountSlides} interval={5000} />
          </Hidden>
        </div>
      </div>
    </section>
  );
};

export default HomeBanner;
