import { makeStyles } from '@material-ui/core/styles';
import bgSvg from '@backgrounds/Testimonials/bg_testimonials_to_ordering.svg';

// overlap backgrounds
// const overlap = 300;

const beforeAfter = {
  content: "''",
  position: 'absolute',
  zIndex: 0,
  backgroundRepeat: 'no-repeat',
};

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'relative',
    minHeight: 500,
    backgroundColor: theme.palette.white,
    paddingTop: '2rem',
    '&::after': {
      ...beforeAfter,
      top: '100%',
      left: 0,
      right: 0,
      backgroundImage: `url(${bgSvg})`,
      minHeight: '15vh',
      backgroundSize: 'cover',
      backgroundPosition: 'top center',
    },
  },
  content: {
    maxWidth: 800,
    margin: '0 auto',
    justifyContent: 'center',
    position: 'relative',
  },
  title: {
    ...theme.typography.h2PurpleText,
  },
}));
