import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Hidden } from '@material-ui/core';
import clsx from 'clsx';
import indexOf from 'lodash/indexOf';
import concat from 'lodash/concat';
import map from 'lodash/map';
import includes from 'lodash/includes';

import categories from '@config/Portfolio/filters.json';
import { useStyles } from './styles';
import Carousel from '@unicat/Portfolio/Carousel';
import Gallery from '@unicat/Gallery';

const GalleryFilter = () => {
  const styles = useStyles();

  const [selected, setSelected] = useState([]); // selected filters

  const click = (id) => () => {
    let arr = [...selected];
    const idx = indexOf(selected, id);
    arr = idx !== -1 ? arr.splice(idx, 1) && arr : concat(arr, id);
    setSelected(arr);
  };

  useLayoutEffect(() => {
    if (window !== undefined) {
      const oldFilters = localStorage.getItem('filters');
      if ((oldFilters ?? '').trim()) {
        setSelected(oldFilters.split(','));
      }
    }
  }, []);

  useEffect(() => {
    if (window !== undefined) {
      localStorage.setItem('filters', selected.toString());
    }
  }, [selected]);

  return (
    <>
      <div className={styles.filters}>
        <Hidden only={['xs', 'sm']}>
          {map(categories, (item) => {
            if (item.isActive) {
              const isSelected = includes(selected, item.id);
              return (
                <div
                  key={item.id}
                  onKeyPress={click(item.id)}
                  onClick={click(item.id)}
                  role="button"
                  tabIndex="0"
                  className={clsx([styles.filter, isSelected && styles.selected, !isSelected && styles.untouched])}>
                  {item.name}
                </div>
              );
            }
          })}
        </Hidden>
      </div>
      <Hidden only={['md', 'lg', 'xl']}>
        <Carousel categories={categories} click={click} selected={selected} style={smFilterCarouselStyle} />
      </Hidden>
      <Gallery selected={selected} />
    </>
  );
};

const smFilterCarouselStyle = { margin: '0 auto', width: 'fit-content' };

export default GalleryFilter;
