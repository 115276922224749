import React from 'react';
import { ButtonBack, ButtonNext, CarouselProvider, Slider } from 'pure-react-carousel';
import PropTypes from 'prop-types';

import SlideContainer from '../SlideContainer';
import { ReactComponent as Right } from '@assets/icons/controls/right.svg';
import { ReactComponent as Left } from '@assets/icons/controls/left.svg';
import { useStyles } from './styles';

const Carousel = ({ categories, click, selected, style = {} }) => {
  const classes = useStyles();
  return (
    <CarouselProvider
      style={{ ...style }}
      naturalSlideHeight={100}
      naturalSlideWidth={500}
      totalSlides={categories?.length ?? 0}
      infinite={true}>
      <div className={classes.carouselInner}>
        <ButtonBack className={classes.btnPrev}>
          {' '}
          <Left />
        </ButtonBack>
        <Slider className={classes.slider}>
          {categories.map((item, i) => (
            <SlideContainer item={item} selected={selected} click={click} key={item.id} index={i} />
          ))}
        </Slider>
        {/* <ButtonforCarousel bgcolor="white" /> */}
        <ButtonNext className={classes.btnNext}>
          {' '}
          <Right />
        </ButtonNext>
      </div>
    </CarouselProvider>
  );
};

Carousel.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  click: PropTypes.func,
  selected: PropTypes.arrayOf(PropTypes.string).isRequired,
  style: PropTypes.object,
};

export default Carousel;
