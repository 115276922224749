import React, { useMemo } from 'react';
import t from '@unicat/i18n/Translate';

import { useStyles } from './styles';
import { linkButtonColorSchemas } from '@styles/styles';

import homeSlides, { homeBannerTitle, CHANGE_TIME } from '@config/HomeBanner';
import { anchors } from '@config/headerRoutes';

import BannerCarousel from '@unicat/UI/BannerCarousel';
import StyledLinkButton from '@unicat/UI/StyledLinkButton';

const HomeBanner = () => {
  const linkButtonColors = linkButtonColorSchemas();
  const classes = useStyles();

  const mainPhraseArray =
    useMemo(() => {
      return homeBannerTitle.split(' ');
    }, [homeBannerTitle]) ?? [];

  return (
    <section id={anchors.HomeTop} className={classes.wrapper}>
      <div className={classes.content}>
        <div className={classes.inner}>
          {/** left block */}
          <div className={classes.magic}>
            <h1 className={classes.uniqueTitle}>
              {mainPhraseArray.map((x, i) => (
                <span key={x + i}>{x} </span>
              ))}
            </h1>
            <StyledLinkButton className={linkButtonColors.yellow}>
              <p>{t('Start the magic')}</p>
            </StyledLinkButton>
          </div>
          {/** carousel block */}
          <BannerCarousel slides={homeSlides} interval={CHANGE_TIME} />
        </div>
      </div>
    </section>
  );
};

export default HomeBanner;
