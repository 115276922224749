import React from 'react';
import { useStyles } from './styles';
// import bg1 from '@backgrounds/AboutUs/family_photo.webp'; // 673x556, 188kb
import bg1 from '@backgrounds/AboutUs/img_family_photo.webp'; // 654x400, 40kb
import { anchors } from '@config/headerRoutes';
import t from '@unicat/i18n/Translate';

const AboutUs = () => {
  const classes = useStyles();

  const multiline = (line) => line.split('\n').reduce((r, c, x) => (r ? [...r, <br key={x} />, c] : [c]), null);

  return (
    <section className={classes.wrapper}>
      <div className={classes.aboutUs}>
        <h2 id={anchors.AboutUs} className={classes.title}>
          {t('About Us')}
        </h2>
        <div className={classes.content}>
          <div className={classes.text}>
            <p>
              {multiline(
                t(
                  'You want to know us closer and we are highly welcome for that. ' +
                    'We are a small family startup that only begins own journey in ' +
                    'confectionary business.',
                ),
              )}
            </p>
            <p>
              {multiline(
                t(
                  'When I saying we - I mean several people:\n' +
                    'Me - I`m the maker of all cakes that you see;\n' +
                    'My husband - he does the technical support (website) and doing the deliveries;\n' +
                    'My daughters - 3.5 and 6 years old kids, they are the best critics ' +
                    'of any happy birthday cake I made.',
                ),
              )}
            </p>
          </div>
        </div>
        <img src={bg1} alt={t('Our Family Photo')} className={classes.imgFamily} />
      </div>
    </section>
  );
};

export default AboutUs;
