import photoImage from '@backgrounds/Testimonials/img_generic_filler_v2.webp'; // 800x530, 351kb

const slidesInfos = [
  {
    image: {
      link: photoImage,
      title: 'first',
    },
    rate: 5.0,
    story:
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Delectus enim excepturi fuga hic id ipsum mollitia nulla quo quos vero!',
  },
  {
    image: {
      link: photoImage,
      title: 'second',
    },
    rate: 4.2,
    story:
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Atque dolorum earum laudantium, minima praesentium quae quidem quis recusandae similique voluptas!',
  },
  {
    image: {
      link: photoImage,
      title: 'third',
    },
    rate: 4.6,
    story:
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto culpa cupiditate distinctio fugiat modi nihil non quisquam sit voluptatum! Laborum!',
  },
];

export default slidesInfos;
