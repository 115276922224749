import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import t from '@unicat/i18n/Translate';
import { CarouselProvider, Slider, Slide, Image } from 'pure-react-carousel';
import { useStyles } from './styles';

const BannerCarousel = ({ width = undefined, height = undefined, slides = [], infinite = true, interval = 0 }) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <CarouselProvider
        infinite={infinite}
        totalSlides={slides.length ?? 0}
        naturalSlideHeight={height ? height / 80 : 550}
        naturalSlideWidth={width ? width / 80 : 500}
        interval={interval}
        isPlaying={!!interval}
        isIntrinsicHeight={true}
        dragEnabled={true}>
        {/* ul > li > div > img */}
        <Slider className={clsx([classes.slider])}>
          {(slides ?? []).map((x, index) => (
            <Slide
              index={index}
              key={index}
              aria-label={t(x.alt)}
              className={clsx([classes.slide, classes.imageShadow, classes[x.shadowStyle]])}>
              <Image
                hasMasterSpinner
                src={x.link}
                title={x.title}
                className={clsx([classes.imageSlide, classes[x.imageStyle]])}
                alt={t(x.alt)}
                width={x.width}
                height={x.height}
              />
            </Slide>
          ))}
        </Slider>
      </CarouselProvider>
    </div>
  );
};

BannerCarousel.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  slides: PropTypes.array.isRequired,
  infinite: PropTypes.bool,
  interval: PropTypes.number,
};

export default BannerCarousel;
