import React from 'react';
import t from '@unicat/i18n/Translate';
import { useStyles } from './styles';
import TextLinkParser from '@unicat/TextLinkParcer';
import { anchors } from '@config/headerRoutes';

const ShortIntro = () => {
  const classes = useStyles();

  return (
    <section id={anchors.ShortIntro} className={classes.wrapper}>
      <div className={classes.content}>
        <h2 className={classes.title}>{t('Your Family Confectionery')}</h2>
        <div className={classes.text}>
          <p>
            <TextLinkParser string={t('We are a small family startup.')} />
          </p>
          <p>
            <TextLinkParser
              string={t('Our passion is to develop high-quality best-look great-taste cakes at a reasonable price')}
            />
          </p>
        </div>
      </div>
    </section>
  );
};

export default ShortIntro;
