import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { useStyles } from './styles';

const CakeCtaButton = ({ hovered, action = () => {} }) => {
  const classes = useStyles();

  return (
    <>
      <button className={clsx([classes.button, hovered ? classes.hovered : ''])} onClick={action}>
        <span>Buy</span>
      </button>
    </>
  );
};

CakeCtaButton.propTypes = {
  hovered: PropTypes.bool.isRequired,
  action: PropTypes.func.isRequired,
};

export default CakeCtaButton;
