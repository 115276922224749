import React from 'react';
import { useStyles } from './styles';
import clsx from 'clsx';
import bubble from '@backgrounds/Ordering/bubble.svg';
import t from '@unicat/i18n/Translate';
import cat from '@backgrounds/Ordering/cat__box.webp';

const CatBox = () => {
  const classes = useStyles();
  return (
    <>
      <div className={clsx([classes.catWrapper])}>
        <div className={clsx([classes.bubble, classes.relative])}>
          <img src={bubble} alt={t('Cloud comment')} className={classes.bubble} />
          <p className={classes.bubbleText}>{t('Your unique cake will be delivered in this wonderful box')}</p>
        </div>
        <img src={cat} alt={t('Wonderful Box')} className={clsx([classes.imageCat])} />
      </div>
    </>
  );
};

export default CatBox;
