import { makeStyles } from '@material-ui/core/styles';

const scrollbarHeight = 6;
const scrollbarColor = '#222';

export const useStyles = makeStyles((theme) => ({
  filters: {
    display: 'flex',
    maxWidth: '100%',
    margin: 'auto',
    justifyContent: 'center',
    overflowX: 'auto',
    [theme.breakpoints.down('sm')]: {
      minWidth: 150,
      margin: 'auto',
    },
    // firefox scroll
    scrollbarWidth: 'thin',
    scrollbarColor,
    // webkit-based scroll
    '&::-webkit-scrollbar': {
      height: scrollbarHeight,
      backgroundColor: theme.palette.darkPurple,
      cursor: 'scroll',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.purple,
    },
  },
  filter: {
    ...theme.typography.normalPurpleText,
    fontSize: '1.25rem',
    padding: '.6rem 1.375rem',
    borderRadius: '1.25rem',
    ...theme.buttonOutline,
    margin: '0 0.5rem',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('sm')]: {
      minWidth: 150,
      maxWidth: 400,
      margin: 'auto',
    },
    carousel: {
      display: 'flex',
    },
  },
  selected: {
    backgroundColor: theme.palette.pinkBackgroundColor, // '#fef0f1',
  },
  untouched: {
    backgroundColor: 'transparent',
  },
}));
