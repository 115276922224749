import { makeStyles } from '@material-ui/core/styles';
import bgSvg from '@backgrounds/OurAdvatages/bg_our_advantages.svg';
import box_bottom from '@backgrounds/OurAdvatages/bg_box_bottom.svg'; // 206x172, 2kb
import box_top from '@backgrounds/OurAdvatages/bg_box_top.svg'; // 254x232, 5kb
import thunder_from_box_1 from '@assets/icons/thunder_from_box_1.svg';
import thunder_from_box_2 from '@assets/icons/thunder_from_box_2.svg';

const beforeAfter = {
  content: "''",
  position: 'absolute',
  zIndex: -1,
  backgroundRepeat: 'no-repeat',
};

export const useStyles = makeStyles((theme) => ({
  advantagesWrapper: {
    position: 'relative',
    minHeight: 1100,
    whiteSpace: 'pre-line',
    display: 'flex',
    backgroundImage: `url(${bgSvg})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom',
    backgroundSize: '100% auto',
    paddingBottom: 100,
    '&::after': {
      ...beforeAfter,
      backgroundImage: `url(${box_bottom})`,
      right: 0,
      bottom: 0,
      left: 0,
      height: '10vw',
      backgroundSize: '100% 100%',
      backgroundPosition: 'center',
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    [theme.breakpoints.down('sm')]: {
      minHeight: '600px',
    },
    [theme.breakpoints.down('xs')]: {
      minHeight: '300px',
    },
  },
  titleWrapper: {
    width: '100%',
    margin: 'auto',
    fontSize: 40,
    position: 'relative',
    maxWidth: '900px',
    '&::before': {
      ...beforeAfter,
      backgroundImage: `url(${box_top})`,
      backgroundSize: 'cover',
      left: 0,
      bottom: 0,
      height: '12vw',
      width: '12vw',
      [theme.breakpoints.down('xs')]: {
        display: 'none',
        padding: '0 10px',
      },
    },
  },

  content: {
    margin: 'auto',
    minHeight: 600,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: '0 40px',
    },
    [theme.breakpoints.down('xs')]: {
      minHeight: 400,
    },
  },
  contentInner: {
    minHeight: 500,
    position: 'relative',
    margin: 'auto',
    width: '100%',
    maxWidth: 1100,
    padding: '3vh 0',
    backgroundImage: `url(${thunder_from_box_2})`,
    backgroundPosition: 'left bottom',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.down('xs')]: {
      backgroundImage: 'none',
      minHeight: 0,
    },
    '&::before': {
      ...beforeAfter,
      backgroundImage: `url(${thunder_from_box_1})`,
      right: 0,
      top: '-3vw',
      height: '8vw',
      width: '6vw',
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
  },
  blockWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  block: {
    maxWidth: '261px',
    textAlign: 'left',
    margin: '2.5rem',
    [theme.breakpoints.down('sm')]: {
      margin: '0.8rem',
    },
  },
  title: {
    ...theme.typography.titlePurpleText,
  },
  text: {
    ...theme.typography.normalGrayText,
    paddingTop: '20px',
  },
  titleWr: {
    ...theme.typography.h2PurpleText,
    marginBottom: 0,
    [theme.breakpoints.down('xs')]: {
      textAlign: 'left',
    },
  },

  sliderControl: {},
}));
