// import home_banner from '@backgrounds/HomeBanner/img_cropped_cake.webp'; // 498x549, 46kb
import banner_unicorn from '@backgrounds/HomeBanner/bg_020_unicorn.webp'; // 500x550, 67kb
import banner_basket from '@backgrounds/HomeBanner/bg_024_basket_of_flowers.webp'; // 500x550, 48kb
import banner_magnolia from '@backgrounds/HomeBanner/bg_026_magnolia.webp'; // 500x550, 84b
import banner_softness from '@backgrounds/HomeBanner/bg_027_softness.webp'; // 500x550, 92kb
import banner_swan from '@backgrounds/HomeBanner/bg_043_white_swan.webp'; // 500x550, 44kb
// import banner_unicorn_2 from '@backgrounds/HomeBanner/bg_044_unicorn.webp'; // 500x550, 53kb
import banner_klarna from '@backgrounds/HomeBanner/bg_054_klarna_steampunk_cat.webp'; // 500x550, 104kb
// import banner_unicat from '@backgrounds/HomeBanner/bg_056_unicat.webp'; // 500x550, 68kb
// import banner_pink from '@backgrounds/HomeBanner/bg_071_pink_ellie.webp'; // 500x550, 63kb
// import banner_equestrian from '@backgrounds/HomeBanner/bg_077_equestian_girls.webp'; // 500x550, 97kb
// import banner_cat from '@backgrounds/HomeBanner/bg_081_cat_cake.webp'; // 500x550, 65kb
import banner_geoda from '@backgrounds/HomeBanner/bg_084_geoda_ametist.webp'; // 500x550, 105kb
import banner_alice from '@backgrounds/HomeBanner/bg_095_alice_in_wonderland.webp'; // 500x550, 93kb
import banner_black from '@backgrounds/HomeBanner/bg_113_black_strawberry.webp'; // 500x550, 85kb
import banner_mlp from '@backgrounds/HomeBanner/bg_114_my_little_pony.webp'; // 500x550, 98kb
import banner_air from '@backgrounds/HomeBanner/bg_144_teddy_air_balloon.webp'; // 500x550, 43kb
import validate from '@config/Validators/bannercarousel.validator';

export default validate([
  {
    link: banner_unicorn,
    title: 'Unicorn Cake',
    alt: 'Do you believe in Unicorns? They exists! Unicorn cake!',
    width: 498,
    height: 549,
    shadowStyle: '',
    imageStyle: '',
  },
  {
    link: banner_basket,
    title: 'Basket Of Flowers',
    alt: 'The best present for your second part - Basket of Flowers!',
    width: 498,
    height: 549,
  },
  {
    link: banner_magnolia,
    title: 'Magnolia Flowers',
    alt: '',
    width: 498,
    height: 549,
  },
  {
    link: banner_softness,
    title: 'Softness',
    alt: '',
    width: 498,
    height: 549,
  },
  {
    link: banner_swan,
    title: 'White Swan',
    alt: '',
    width: 498,
    height: 549,
  },
  // {
  //   link: banner_unicorn_2,
  //   title: '',
  //   alt: '',
  //   width: 498,
  //   height: 549,
  // },
  {
    link: banner_klarna,
    title: 'Klarna SteamPunk Cat',
    alt: '',
    width: 498,
    height: 549,
  },
  // {
  //   link: banner_unicat,
  //   title: '',
  //   alt: '',
  //   width: 498,
  //   height: 549,
  // },
  // {
  //   link: banner_pink,
  //   title: '',
  //   alt: '',
  //   width: 498,
  //   height: 549,
  // },
  // {
  //   link: banner_equestrian,
  //   title: '',
  //   alt: '',
  //   width: 498,
  //   height: 549,
  // },
  // {
  //   link: banner_cat,
  //   title: '',
  //   alt: '',
  //   width: 498,
  //   height: 549,
  // },
  {
    link: banner_geoda,
    title: 'Geoda Amethyst',
    alt: '',
    width: 498,
    height: 549,
  },
  {
    link: banner_alice,
    title: 'Alice in Wonderland',
    alt: '',
    width: 498,
    height: 549,
  },
  {
    link: banner_black,
    title: 'Black Strawberry',
    alt: '',
    width: 498,
    height: 549,
  },
  {
    link: banner_mlp,
    title: 'My Little Pony',
    alt: '',
    width: 498,
    height: 549,
  },
  {
    link: banner_air,
    title: 'Teddy Air Balloon',
    alt: '',
    width: 498,
    height: 549,
  },
  // {
  //   link: home_banner,
  //   title: 'home banner',
  //   alt: 'First Winter Cake',
  //   width: 498,
  //   height: 549,
  // },
]);

export const homeBannerTitle = 'make unique cake';
/** Home banner animation in millis. */
export const CHANGE_TIME = 3500;
