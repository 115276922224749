import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Cloned from './Cloned';
import { useStyles } from '@unicat/CakeCard/styles';

const FALLBACK_INTERVAL = 1500;

const CardWrapper = ({ children, images, interval }) => {
  const styles = useStyles();

  const [hovered, setHovered] = useState(false);
  const [nextImage, setNextImage] = useState(null);

  /* change images with an interval */
  useEffect(() => {
    let int;

    if (hovered) {
      int = setInterval(() => {
        if (nextImage) {
          setNextImage((prevState) => {
            const now = images.indexOf(prevState);
            return images[now >= 0 ? (now < images.length - 1 ? now + 1 : 0) : 0];
          });
        } else {
          setNextImage(images[1] ?? images[0]);
        }
      }, interval ?? FALLBACK_INTERVAL);
    } else {
      clearInterval();
    }

    return () => clearInterval(int);
  }, [hovered]);

  return (
    <>
      <div onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)} className={styles.wrapper}>
        {/* clone of node and add props 'hovered' & 'nextImage' */}
        <Cloned hovered={hovered} nextImage={nextImage}>
          {children}
        </Cloned>
      </div>
    </>
  );
};

CardWrapper.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  interval: PropTypes.number,
};

export default CardWrapper;
