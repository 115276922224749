import React from 'react';
import t from '@unicat/i18n/Translate';
import { CarouselProvider, Slider } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import map from 'lodash/map';

import { useStyles } from './styles';
import slidesInfos from '@config/Testimonials';
import SlideContainer from '@unicat/Testimonials/slide-container/SlideContainer';
import CarouselArrows from '../UI/CarouselArrows';
import { anchors } from '@config/headerRoutes';

const Testimonials = () => {
  const classes = useStyles();

  return (
    <section id={anchors.Testimonials} className={classes.wrapper}>
      <h3 className={classes.title}>{t('Testimonials')}</h3>
      <div className={classes.content}>
        {/*
        naturalSlideHeight & naturalSlideWidth are equal input number * 80px
        I don't now why.
          */}
        <CarouselProvider
          naturalSlideHeight={6}
          naturalSlideWidth={10}
          totalSlides={slidesInfos?.length ?? 0}
          infinite={true}>
          {/** Slider */}
          <Slider>
            {map(slidesInfos, (x, index) => (
              <SlideContainer slideInfo={x} index={index} key={x.story} />
            ))}
          </Slider>
          <CarouselArrows />
        </CarouselProvider>
      </div>
    </section>
  );
};

export default Testimonials;
