import React from 'react';
import PropTypes from 'prop-types';

const mdLinkPattern = /\[[^\]]*\]\([^)]*\)*/g; // example: [station](https://goo.gl)
const pattern_title = /\[[^\]]*\]/g; // example: [station]
const pattern_link = /\([^)]*\)*/g; // example: (https://goo.gl)
const pattern_bold = /\*(.*?)\*/g; // example: *Text*
const pattern_italic = /\^(.*?)\^/g; // example: ^Italic Text^
const pattern_underline = /\+(.*?)\+/g; // example: +Underline Texts+

const TextLinkParser = ({ string = '' }) => {
  const replaceLinks = (mdLink = '') => {
    const title = mdLink?.match(pattern_title)?.[0].slice(1, -1);
    const link = mdLink?.match(pattern_link)?.[0].slice(1, -1);

    // TODO: customize link presentation, add icon, etc.
    return `<a style="text-decoration: underline" href="${encodeURI(link)}" target="_blank">${title}</a>`;
  };
  const addStyleBold = (text) => {
    const textBold = text?.match(pattern_bold)?.[0].slice(1, -1);
    return `<strong>${textBold}</strong>`;
  };
  const addStyleItalic = (text) => {
    const textItalic = text?.match(pattern_italic)?.[0].slice(1, -1);
    return `<i>${textItalic}</i>`;
  };
  const addStyleUnderline = (text) => {
    const textUnderline = text?.match(pattern_underline)?.[0].slice(1, -1);
    return `<span style="text-decoration:underline">${textUnderline}</span>`;
  };

  const matched = string
    ?.replace(mdLinkPattern, (match) => replaceLinks(match))
    .replace(pattern_bold, (match) => addStyleBold(match))
    .replace(pattern_italic, (match) => addStyleItalic(match))
    .replace(pattern_underline, (match) => addStyleUnderline(match));

  return (
    <span
      dangerouslySetInnerHTML={{
        __html: `<span>${matched}</span>`,
      }}
    />
  );
};

TextLinkParser.propTypes = {
  string: PropTypes.string.isRequired,
};

export default TextLinkParser;
