import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import clsx from 'clsx';
import t from '@unicat/i18n/Translate';

import FavoriteIcon from '@material-ui/icons/Favorite';
import ModeCommentIcon from '@material-ui/icons/ModeComment';
import { useStyles } from '@unicat/InstaFeed/InstagramGrid/styles';

export default function InstaCard({ id, likes, comments, localFile }) {
  const classes = useStyles();
  return (
    <a
      href={`https://www.instagram.com/p/${id}/`}
      target="_blank"
      rel="noreferrer noopener"
      // tabindex={tabIndex}
      className={classes.complexLink}>
      <div className={classes.wrapper}>
        <div className={classes.inner}>
          {/** image */}
          <Img fluid={localFile.childImageSharp.fluid} alt={t('Instagram Post Image')} />
        </div>
        <div className={classes.inner}>
          <div className={clsx([classes.flex])}>
            {/** likes */}
            {likes && (
              <div className={clsx([classes.flex, classes.info])}>
                <FavoriteIcon />
                <p className={classes.likes}>{likes}</p>
              </div>
            )}
            {/** comments */}
            {comments && (
              <div className={clsx([classes.flex, classes.info])}>
                <ModeCommentIcon />
                <p className={classes.comments}>{comments}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </a>
  );
}

InstaCard.propTypes = {
  id: PropTypes.string.isRequired,
  likes: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  comments: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  localFile: PropTypes.object.isRequired,
};
