import { makeStyles } from '@material-ui/core/styles';

import bgLeft from '@backgrounds/HomeBanner/bg_top_left_white_liquid.svg'; // 906x493, 305b
import bgRight from '@backgrounds/HomeBanner/bg_right_white_liquid.svg'; // 1433x1075, 363b

const beforeAfter = {
  content: "''",
  position: 'absolute',
  zIndex: -1,
  backgroundRepeat: 'no-repeat',
  transition: 'all 0.25s',
};

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    textAlign: 'left',
    paddingTop: theme.palette.contentOffsetTopBottom,
    paddingBottom: theme.palette.contentOffsetTopBottom,
    minHeight: `max(${theme.palette.contentMinimalHeight}px, 110vh)`,
    overflow: 'visible',
    position: 'relative',
    '&::before': {
      ...beforeAfter,
      width: 570,
      height: 340,
      backgroundImage: `url(${bgLeft})`,
      top: 0,
      left: 0,
      backgroundSize: '100% auto',
      backgroundPosition: 'top -6vh left -10vh',
      [theme.breakpoints.down('md')]: {
        backgroundPosition: 'top -10vh left -20vh',
      },
      [theme.breakpoints.down('sm')]: {
        backgroundPosition: 'top -10vh left -30vh',
      },
    },
    '&::after': {
      ...beforeAfter,
      position: 'absolute',
      backgroundImage: `url(${bgRight})`,
      right: 0,
      top: 0,
      bottom: 0,
      left: 0,
      height: '100%',
      width: '100%',
      backgroundSize: 'auto 100%',
      backgroundPosition: 'right 0vh top -12vh',
      [theme.breakpoints.down(1500)]: {
        backgroundPosition: 'right 0vh top -10vh',
      },
      [theme.breakpoints.down('md')]: {
        backgroundPosition: 'left calc(100% + 1px) top',
        backgroundSize: 'auto',
      },
      [theme.breakpoints.down('sm')]: {
        backgroundSize: 'auto 100%',

        backgroundPosition: 'left 10% center',
      },
    },
  },
  inner: {
    display: 'flex',
    maxWidth: 1200,
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
    },
  },
  magic: {
    [theme.breakpoints.down('sm')]: {
      maxWidth: 400,
      textAlign: 'center',
      margin: '0 auto',
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '90%',
    },
  },
  uniqueTitle: {
    fontSize: '5.5rem',
    fontFamily: theme.typography.textsFontFamily,
    color: theme.palette.pinkTextColor,
    minWidth: 500,
    maxWidth: 550,
    width: '100%',
    textTransform: 'uppercase',
    [theme.breakpoints.down('sm')]: {
      minWidth: 'unset',
    },
    lineHeight: 0.9,
    '& span:nth-child(2)': {
      fontSize: '6.0rem',
      fontFamily: theme.typography.lemonBird,
      color: theme.palette.purple,
      fontWeight: 400,
      lineHeight: 'inherit',
    },
  },
  unique: {},
  cake: {},
}));
