import React from 'react';
import PropTypes from 'prop-types';

// clone elements and add 'hovered' & 'nextImage' to element props
// noinspection ES6ShorthandObjectProperty
class Cloned extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.renderChildren = this.renderChildren.bind(this);
  }

  renderChildren() {
    return React.Children.map(this.props.children, (child) => {
      const { hovered, nextImage } = this.props;
      const inject = child.type === 'img' ? {} : { hovered, nextImage };
      return React.cloneElement(child, inject);
    });
  }

  render() {
    return <>{this.renderChildren()}</>;
  }
}

Cloned.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element),
  hovered: PropTypes.bool.isRequired,
  nextImage: PropTypes.string,
};

export default Cloned;
