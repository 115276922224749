import { makeStyles } from '@material-ui/core/styles';

const visible = {
  transform: 'translateY(-100%) translateZ(0)',
  zIndex: 1,
};

export const useStyles = makeStyles((theme) => ({
  bar: {
    width: '100%',
    color: 'white',
    fontFamily: theme.typography.textsFontFamily,
    backgroundColor: theme.palette.purple,
    position: 'absolute',
    top: '100%',
    left: 0,
    right: 0,
    display: 'grid',
    borderRadius: 10,
    border: 0,
    gridTemplateColumns: '80% 20%',
    alignItems: 'center',
    transition: 'all 0.5s',
    zIndex: -2,
    ...theme.buttonOutline,
    '&:focus-visible': {
      ...visible,
      outline: 'none',
      filter: `drop-shadow(0px 10px 32px ${theme.palette.shadowColor})`,
    },
  },
  inner: {
    display: 'grid',
    gridTemplateRows: '1fr 1fr',
    backgroundColor: theme.palette.darkPurple,
    borderTopLeftRadius: 'inherit',
    borderBottomLeftRadius: 'inherit',
    textAlign: 'left',
  },
  title: {
    fontSize: '1.5rem',
    fontWeight: 800,
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(2),
  },
  params: {
    display: 'flex',
    fontSize: '0.9rem',
    lineHeight: 1,
    paddingLeft: theme.spacing(3),
    paddingBottom: theme.spacing(2),

    '& span': {
      display: 'flex',
      alignItems: 'center',
      marginRight: '1.9rem',
      '&:last-child': {
        marginRight: 'unset',
      },
    },
  },
  arrow: {
    textAlign: 'center',
  },
  visible: {
    ...visible,
    filter: `drop-shadow(0px 10px 32px ${theme.palette.shadowColor})`,
  },
}));
