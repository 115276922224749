import React from 'react';
import { Slide } from 'pure-react-carousel';
import PropTypes from 'prop-types';
import t from '@unicat/i18n/Translate';
import { useStyles } from '@unicat/OurAdvantages/styles';

const SlideContainer = ({ text, title, index }) => {
  const classes = useStyles();

  return (
    <Slide index={index}>
      <div className={classes.block}>
        <div className={classes.title}>{t(title)}</div>
        <div className={classes.text}>{t(text)}</div>
      </div>
    </Slide>
  );
};

SlideContainer.propTypes = {
  index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  text: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default SlideContainer;
