import React from 'react';
import PropTypes from 'prop-types';
import { Image, Slide } from 'pure-react-carousel';
import t from '@unicat/i18n/Translate';

import { useStyles } from './styles';

const SlideContainer = ({ slideInfo, index }) => {
  const classes = useStyles();

  const {
    image: { link, title },
    story,
  } = slideInfo;

  return (
    <Slide index={index} className={classes.slideContainer}>
      <div className={classes.container}>
        <div className={classes.frame}>
          <Image
            src={link}
            alt={t(title)}
            title={t(title)}
            hasMasterSpinner
            width={300}
            height={184}
            className={classes.photo}
          />
          <div className={classes.rating}>5</div>
        </div>
        <p className={classes.story}>{t(story)}</p>
      </div>
    </Slide>
  );
};

SlideContainer.propTypes = {
  slideInfo: PropTypes.shape({
    image: PropTypes.shape({
      link: PropTypes.string,
      title: PropTypes.string,
    }),
    rate: PropTypes.number,
    story: PropTypes.string,
  }).isRequired,
  index: PropTypes.number.isRequired,
};

export default SlideContainer;
