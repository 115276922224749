import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  image: {
    maxWidth: '100%',
    zIndex: -2,
    transition: 'transform 0.5s',
    borderRadius: 'inherit',
  },
  wrapper: {
    borderRadius: '0.8rem',
    position: 'relative',
    display: 'inline-block',
    margin: '0 0 1rem',
    width: '100%',
    overflowX: 'visible',
    overflowY: 'hidden',
  },
  // hide original image for set dimensions to easing block
  origin: {
    position: 'relative',
    // zIndex: -1000,
  },
  transparent: {
    position: 'absolute',
    zIndex: -1000,
  },
  imageChanger: {
    position: 'absolute !important',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: 'inherit',
    transition: 'transform 0.5s',
  },

  hover: {
    [theme.breakpoints.up('md')]: {
      '&:hover': {
        transform: 'scale(0.9);',
      },
    },
  },
}));

// inline image styles
export const imageStyle = {
  width: '100%',
  height: '100%',
  borderRadius: 'inherit',
  objectFit: 'cover',
  objectPosition: 'center',
};
