import React, { useContext } from 'react';
import { useStyles } from './styles';
import t from '@unicat/i18n/Translate';
import { config } from '@config';
import Language from '@unicat/i18n/LanguageContext';

const FloatButton = () => {
  const classes = useStyles();
  const language = useContext(Language);
  const href = config.forms[language.lang];

  const handleOnClick = (event) => {
    event.preventDefault();

    window.open(href, '_blank');
  };
  return (
    <>
      <div className={classes.floatOverlap} />
      <div className={classes.floatingBut}>
        <div className={classes.text} onClick={handleOnClick} role={'presentation'} tabIndex={'0'}>
          {t('Make an Order')}{' '}
        </div>
      </div>
    </>
  );
};

export default FloatButton;
