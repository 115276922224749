import PropTypes from 'prop-types';
import React from 'react';
import { useStyles } from './styles';
import { ButtonBack, ButtonNext } from 'pure-react-carousel';
import { ReactComponent as Right } from '@assets/icons/controls/right.svg';
import { ReactComponent as Left } from '@assets/icons/controls/left.svg';
import { ReactComponent as RightWhite } from '@assets/icons/controls/right_white.svg';
import { ReactComponent as LeftWhite } from '@assets/icons/controls/left_white.svg';

const CarouselArrows = ({ bgColor }) => {
  const classes = useStyles();

  function renderLeftArrow() {
    return bgColor === 'white' ? <LeftWhite /> : <Left />;
  }

  function renderRightArrow() {
    return bgColor === 'white' ? <RightWhite /> : <Right />;
  }

  return (
    <div className={classes.buttons}>
      {/* Prev Button */}
      <ButtonBack className={classes.btnPrev}>{renderLeftArrow()}</ButtonBack>
      {/* Next Button */}
      <ButtonNext className={classes.btnNext}>{renderRightArrow()}</ButtonNext>
    </div>
  );
};

CarouselArrows.propTypes = {
  bgColor: PropTypes.string.isRequired,
};

CarouselArrows.defaultProps = {
  bgColor: '',
};

export default CarouselArrows;
