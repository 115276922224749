import { makeStyles } from '@material-ui/core/styles';
import bgForImg1 from '@backgrounds/WeCreate/bg_mini1.svg';
import bgForImg2 from '@backgrounds/WeCreate/bg_mini2.svg';
import bgForImg3 from '@backgrounds/WeCreate/bg_mini3.svg';
import bgForImg4 from '@backgrounds/WeCreate/bg_mini4.svg';
import bgSvg from '@backgrounds/WeCreate/bg_wecreate.svg';
import bgCat from '@backgrounds/WeCreate/bg_cat_simplified.svg';

// 'xs' | 'sm' | 'md' | 'lg' | 'xl'

export const useStyles = makeStyles((theme) => ({
  catHeadContainer: {
    position: 'absolute',
    height: 300,
    width: '100%',
    zIndex: -1000,
  },
  catHead: {
    backgroundImage: `url(${bgCat})`,
    backgroundRepeat: 'no-repeat',
    width: 300,
    height: 300,
    zIndex: -100,
    position: 'absolute',
    transform: 'rotate(11deg)',
    gridColumnStart: 2,
    left: 'calc(36% - 150px)',
  },
  wrapper: {
    zIndex: 100,
    minHeight: 1000,
    [theme.breakpoints.up('lg')]: {
      minHeight: 1300,
    },
    backgroundImage: `url(${bgSvg})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: '40%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    whiteSpace: 'pre-line',
    [theme.breakpoints.down('sm')]: {
      // backgroundPositionX: '47%',
      // backgroundImage: `url(${bgMedium})`,
    },
    [theme.breakpoints.down('xs')]: {
      // backgroundPositionX: '40%',
      // backgroundImage: `url(${bgForMobile})`,
    },
  },
  content: {
    margin: 'auto',
    maxWidth: '1200px',
    width: '100%',
    padding: '0 30px',
  },
  title: {
    ...theme.typography.h2PurpleText,
    color: theme.palette.white,
  },
  categoryTitle: {
    ...theme.typography.normalWhiteText,
    fontSize: '1.5rem',
    marginTop: '2rem',
    fontWeight: 600,
  },
  blocksWrapper: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%',
    margin: '0 auto',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    },
    '& :nth-child(1) div:first-child': {
      backgroundImage: `url(${bgForImg1})`,
    },
    '& :nth-child(2) div:first-child': {
      backgroundImage: `url(${bgForImg2})`,
    },
    '& :nth-child(3) div:first-child': {
      backgroundImage: `url(${bgForImg3})`,
    },
    '& :nth-child(4) div:first-child': {
      backgroundImage: `url(${bgForImg4})`,
    },
  },
  block: {
    [theme.breakpoints.down('sm')]: {
      maxWidth: 140,
      paddingTop: 50,
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: 120,
      paddingTop: 50,
    },
  },
  titleWrapper: {
    fontSize: 40,
    fontWeight: 1000,
    [theme.breakpoints.down('xs')]: {
      textAlign: 'left',
    },
  },
  imgWrapper: {
    width: 230,
    height: 246,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',

    [theme.breakpoints.down('sm')]: {
      width: 140,
      height: 150,
    },
    [theme.breakpoints.down('xs')]: {
      width: 120,
      height: 130,
    },
  },

  img: {
    maxWidth: 162,
    marginBottom: '7px',
    transition: '0.3s',
    '&:hover': {
      marginBottom: '25px',
      cursor: 'pointer',
      transition: '0.3s',
    },
    // [theme.breakpoints.down('sm')]: {
    //   maxWidth: 120,
    //   maxHeight: 130,
    // },
    [theme.breakpoints.down('sm')]: {
      maxWidth: 90,
      maxHeight: 100,
    },
  },
}));
