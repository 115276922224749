import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  loadMore: {
    ...theme.actionLinkButton,
    ...theme.typography.normalWhiteText,
    ...theme.buttonOutline,
    backgroundColor: theme.palette.purple,
  },
}));
