import Img0 from '@assets/cakes/004_cheasecake/img_9408.webp';
import Img1 from '@assets/cakes/004_cheasecake/img_9412.webp';
import Img2 from '@assets/cakes/004_cheasecake/img_9420.webp';

import { validateOne } from '@config/Validators/cake.validator';
import data from './data.json';
// import t from '@unicat/i18n/Translate';

export default validateOne(
  Object.assign({}, data, {
    imgLink: [Img0, Img1, Img2],
  }),
);
