import React from 'react';
import t from '@unicat/i18n/Translate';
import { useStyles } from './styles';
import { linkButtonColorSchemas } from '@styles/styles';
import { ReactComponent as RedirectWhite } from '@assets/icons/redirect_white.svg';
import { anchors } from '@config/headerRoutes';
import { config } from '@config';
import InstagramGrid from '@unicat/InstaFeed/InstagramGrid/Grid';
import InstaCard from '@unicat/InstaFeed/InstaCard/InstaCard';

const InstaFeed = () => {
  const classes = useStyles();
  const linkButtonColors = linkButtonColorSchemas();

  const {
    socialLinks: { instagram },
  } = config;

  return (
    <section id={anchors.IgFeed} className={classes.wrapper}>
      <h3 className={classes.title}>{t('Instagram Feed')}</h3>
      <InstagramGrid card={InstaCard} />
      <a href={instagram} className={linkButtonColors.purple} target={'_blank'} rel={'noreferrer noopener'}>
        <p>
          {t('View more in our Instagram')}&nbsp;
          <RedirectWhite />
        </p>
      </a>
    </section>
  );
};

export default InstaFeed;
