import Img0 from '@assets/cakes/084_geoda_ametist/img_2537.webp';
import Img1 from '@assets/cakes/084_geoda_ametist/img_2543.webp';
import Img2 from '@assets/cakes/084_geoda_ametist/img_2546.webp';

import { validateOne } from '@config/Validators/cake.validator';
import data from './data.json';
// import t from '@unicat/i18n/Translate';

export default validateOne(
  Object.assign({}, data, {
    imgLink: [Img0, Img1, Img2],
  }),
);
