import { default as _001_cat_cake } from './001_cat_cake';
import { default as _002_teddy_bear_cake } from './002_teddy_bear_cake';
import { default as _003_coconut_cake } from './003_coconut_cake';
import { default as _004_cheasecake } from './004_cheasecake';
import { default as _005_velur_heart_cake } from './005_velur_heart_cake';
import { default as _006_new_born } from './006_new_born';
import { default as _007_pink_gold_flowers } from './007_pink_gold_flowers';
import { default as _008_velure_cake } from './008_velure_cake';
import { default as _009_moko } from './009_moko';
import { default as _010_flowers } from './010_flowers';
import { default as _011_easter_bread } from './011_easter_bread';
import { default as _012_cherry_heart } from './012_cherry_heart';
import { default as _013_cherry } from './013_cherry';
import { default as _014_coffee } from './014_coffee';
import { default as _015_diana5ar } from './015_diana5ar';
import { default as _015_happy_bithday_diana_5 } from './015_happy_bithday_diana_5';
import { default as _016_illiusion } from './016_illiusion';
import { default as _017_love_is } from './017_love_is';
import { default as _018_moko } from './018_moko';
import { default as _019_christening } from './019_christening';
import { default as _020_unicorn } from './020_unicorn';
import { default as _021_vanil_strawberry } from './021_vanil_strawberry';
import { default as _022_pink_cream } from './022_pink_cream';
import { default as _023_mermaid } from './023_mermaid';
import { default as _024_flowers_master_class } from './024_flowers_master_class';
import { default as _025_two_layers_peppa_pig } from './025_two_layers_peppa_pig';
import { default as _026_magnolia } from './026_magnolia';
import { default as _027_softness } from './027_softness';
import { default as _028_my_little_pony } from './028_my_little_pony';
import { default as _029_channel_5 } from './029_channel_5';
import { default as _030_nataliia_h_p } from './030_nataliia_h_p';
import { default as _031_honey_bee } from './031_honey_bee';
import { default as _032_unicorn } from './032_unicorn';
import { default as _033_fathers_day } from './033_fathers_day';
import { default as _034_bamse } from './034_bamse';
import { default as _035_charlie_present } from './035_charlie_present';
import { default as _036_oscar } from './036_oscar';
import { default as _037_blueberry_boom } from './037_blueberry_boom';
import { default as _038_christmas_tree } from './038_christmas_tree';
import { default as _039_squirrel } from './039_squirrel';
import { default as _040_maloshariki } from './040_maloshariki';
import { default as _041_spiderman_black } from './041_spiderman_black';
import { default as _042_pink_flamingo } from './042_pink_flamingo';
import { default as _043_white_swan } from './043_white_swan';
import { default as _044_unicorn } from './044_unicorn';
import { default as _045_winter_house } from './045_winter_house';
import { default as _046_black } from './046_black';
import { default as _047_mermaid_candybar } from './047_mermaid_candybar';
import { default as _048_winter_house } from './048_winter_house';
import { default as _049_gnome } from './049_gnome';
import { default as _050_black_jack } from './050_black_jack';
import { default as _051_heart } from './051_heart';
import { default as _052_football } from './052_football';
import { default as _053_frozen_candybar } from './053_frozen_candybar';
import { default as _054_steam_punk_cat } from './054_steam_punk_cat';
import { default as _055_bee_cell } from './055_bee_cell';
import { default as _056_unicat } from './056_unicat';
import { default as _057_unicor_sofia } from './057_unicor_sofia';
import { default as _058_minions } from './058_minions';
import { default as _059_easter_chicken } from './059_easter_chicken';
import { default as _060_pikachu } from './060_pikachu';
import { default as _061_frozen_spiderman } from './061_frozen_spiderman';
import { default as _062_napoleon } from './062_napoleon';
import { default as _063_maloshariki } from './063_maloshariki';
import { default as _064_coder } from './064_coder';
import { default as _065_rainbow_car } from './065_rainbow_car';
import { default as _066_napoleon_2 } from './066_napoleon_2';
import { default as _067_strawberry } from './067_strawberry';
import { default as _068_gymnastics } from './068_gymnastics';
import { default as _069_napoleon_3 } from './069_napoleon_3';
import { default as _070_ninjago } from './070_ninjago';
import { default as _071_pink_ellie } from './071_pink_ellie';
import { default as _072_juventus_ronaldo } from './072_juventus_ronaldo';
import { default as _073_balloons } from './073_balloons';
import { default as _074_lol } from './074_lol';
import { default as _075_teddy_bear_moon } from './075_teddy_bear_moon';
import { default as _076_planetarium } from './076_planetarium';
import { default as _077_equestia_girls } from './077_equestia_girls';
import { default as _078_frozen_elza } from './078_frozen_elza';
import { default as _079_roblox } from './079_roblox';
import { default as _080_lady_shoe } from './080_lady_shoe';
import { default as _081_cat } from './081_cat';
import { default as _082_pink_flamingo_5ar } from './082_pink_flamingo_5ar';
import { default as _083_white_swan } from './083_white_swan';
import { default as _084_geoda_ametist } from './084_geoda_ametist';
import { default as _085_white_pink } from './085_white_pink';
import { default as _086_beaver } from './086_beaver';
import { default as _087_fox } from './087_fox';
import { default as _088_tedy_bear } from './088_tedy_bear';
import { default as _089_unicor_lollypop } from './089_unicor_lollypop';
import { default as _090_frozen_elsa } from './090_frozen_elsa';
import { default as _091_white } from './091_white';
import { default as _092_warzone } from './092_warzone';
import { default as _093_20_years } from './093_20_years';
import { default as _094_spiderman } from './094_spiderman';
import { default as _095_alice_wonderland } from './095_alice_wonderland';
import { default as _096_stars } from './096_stars';
import { default as _097_super_mirror } from './097_super_mirror';
import { default as _098_cubik } from './098_cubik';
import { default as _099_ninjango } from './099_ninjango';
import { default as _100_car } from './100_car';
import { default as _101_frozen_2 } from './101_frozen_2';
import { default as _102_bond_50 } from './102_bond_50';
import { default as _103_omg_44 } from './103_omg_44';
import { default as _104_pilot } from './104_pilot';
import { default as _105_witches } from './105_witches';
import { default as _106_lol } from './106_lol';
import { default as _107_pikachu } from './107_pikachu';
import { default as _108_hidgehog } from './108_hidgehog';
import { default as _109_starwars } from './109_starwars';
import { default as _110_my_birthday } from './110_my_birthday';
import { default as _111_winter_edition } from './111_winter_edition';
import { default as _112_baby_present } from './112_baby_present';
import { default as _113_black_strawberry } from './113_black_strawberry';
import { default as _114_my_little_pony } from './114_my_little_pony';
import { default as _115_winter_light } from './115_winter_light';
import { default as _116_winter_knastomte } from './116_winter_knastomte';
import { default as _117_black_30 } from './117_black_30';
import { default as _118_geoda_teddy_bear } from './118_geoda_teddy_bear';
import { default as _121_winter_silver_tree } from './121_winter_silver_tree';
import { default as _122_father_christmass } from './122_father_christmass';
import { default as _123_winter_taste } from './123_winter_taste';

const ZZ = [
  _001_cat_cake,
  _002_teddy_bear_cake,
  _003_coconut_cake,
  _004_cheasecake,
  _005_velur_heart_cake,
  _006_new_born,
  _007_pink_gold_flowers,
  _008_velure_cake,
  _009_moko,
  _010_flowers,
  _011_easter_bread,
  _012_cherry_heart,
  _013_cherry,
  _014_coffee,
  _015_diana5ar,
  _015_happy_bithday_diana_5,
  _016_illiusion,
  _017_love_is,
  _018_moko,
  _019_christening,
  _020_unicorn,
  _021_vanil_strawberry,
  _022_pink_cream,
  _023_mermaid,
  _024_flowers_master_class,
  _025_two_layers_peppa_pig,
  _026_magnolia,
  _027_softness,
  _028_my_little_pony,
  _029_channel_5,
  _030_nataliia_h_p,
  _031_honey_bee,
  _032_unicorn,
  _033_fathers_day,
  _034_bamse,
  _035_charlie_present,
  _036_oscar,
  _037_blueberry_boom,
  _038_christmas_tree,
  _039_squirrel,
  _040_maloshariki,
  _041_spiderman_black,
  _042_pink_flamingo,
  _043_white_swan,
  _044_unicorn,
  _045_winter_house,
  _046_black,
  _047_mermaid_candybar,
  _048_winter_house,
  _049_gnome,
  _050_black_jack,
  _051_heart,
  _052_football,
  _053_frozen_candybar,
  _054_steam_punk_cat,
  _055_bee_cell,
  _056_unicat,
  _057_unicor_sofia,
  _058_minions,
  _059_easter_chicken,
  _060_pikachu,
  _061_frozen_spiderman,
  _062_napoleon,
  _063_maloshariki,
  _064_coder,
  _065_rainbow_car,
  _066_napoleon_2,
  _067_strawberry,
  _068_gymnastics,
  _069_napoleon_3,
  _070_ninjago,
  _071_pink_ellie,
  _072_juventus_ronaldo,
  _073_balloons,
  _074_lol,
  _075_teddy_bear_moon,
  _076_planetarium,
  _077_equestia_girls,
  _078_frozen_elza,
  _079_roblox,
  _080_lady_shoe,
  _081_cat,
  _082_pink_flamingo_5ar,
  _083_white_swan,
  _084_geoda_ametist,
  _085_white_pink,
  _086_beaver,
  _087_fox,
  _088_tedy_bear,
  _089_unicor_lollypop,
  _090_frozen_elsa,
  _091_white,
  _092_warzone,
  _093_20_years,
  _094_spiderman,
  _095_alice_wonderland,
  _096_stars,
  _097_super_mirror,
  _098_cubik,
  _099_ninjango,
  _100_car,
  _101_frozen_2,
  _102_bond_50,
  _103_omg_44,
  _104_pilot,
  _105_witches,
  _106_lol,
  _107_pikachu,
  _108_hidgehog,
  _109_starwars,
  _110_my_birthday,
  _111_winter_edition,
  _112_baby_present,
  _113_black_strawberry,
  _114_my_little_pony,
  _115_winter_light,
  _116_winter_knastomte,
  _117_black_30,
  _118_geoda_teddy_bear,
  _121_winter_silver_tree,
  _122_father_christmass,
  _123_winter_taste,
];

export default ZZ;
