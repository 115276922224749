import { makeStyles } from '@material-ui/core/styles';

// overlap backgrounds
const overlap = 300;

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    minHeight: 500,
    backgroundColor: 'white',
    paddingBottom: overlap,
    paddingTop: '2rem',
  },
  title: {
    /// OPTIMIZE
    margin: 0,
  },
  link: {
    display: 'inline-block',
    ...theme.actionLinkButton,
    backgroundColor: theme.palette.yellowActionColor,
  },
}));
