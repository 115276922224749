import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { config } from '@config';

import { Experimental, MultiLanguage, OpenGraph, PreConnect, PreLoad, SEO, TwitterCard } from '@unicat/Seo';
// import FacebookMessenger from '@unicat/FacebookMessenger';
import HomeBanner from '@unicat/HomeBanner';
import FloatingButton from '@unicat/FloatingButton';
import WeCreate from '@unicat/WeCreate';
import OurAdvantages from '@unicat/OurAdvantages';
import Testimonials from '@unicat/Testimonials';
import GetDiscount from '@unicat/GetDiscount';
import ShortIntro from '@unicat/ShortIntro';
import Portfolio from '@unicat/Portfolio';
import InstaFeed from '@unicat/InstaFeed';
import AboutUs from '@unicat/AboutUs';
import Ordering from '@unicat/Ordering';
import Footer from '@unicat/Footer';
import Language from '@unicat/i18n/LanguageContext';

export const query = graphql`
  query SiteTitleQuery {
    site {
      meta: siteMetadata {
        title
        description
        author
      }
    }
  }
`;

const HomePage = ({ data, pageContext, location }) => {
  const language = useContext(Language);
  const { lang } = language ?? { lang: config.DefaultLanguage };

  const title = pageContext.pageName;
  const { description } = data.site.meta;

  return (
    <>
      <>
        <SEO title={title} description={description} lang={lang} />
        <MultiLanguage lang={lang} location={location} />
        <TwitterCard title={title} description={description} />
        <OpenGraph title={title} description={description} />
        <PreLoad />
        <PreConnect />
        <Experimental />
      </>
      {/*<FacebookMessenger />*/}
      <div className={'default-container'}>
        <HomeBanner />
        <ShortIntro />
        <WeCreate />
        <OurAdvantages />
        <Portfolio />
        <InstaFeed />
        <AboutUs />
        <Testimonials />
        <Ordering />
        <GetDiscount />
        <FloatingButton />
        <Footer />
      </div>
    </>
  );
};

HomePage.defaultProps = {};
HomePage.propTypes = {
  pageContext: PropTypes.shape({
    id: PropTypes.string.isRequired,
    pageName: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    site: PropTypes.shape({
      meta: PropTypes.shape({
        author: PropTypes.string,
        description: PropTypes.string,
        title: PropTypes.string,
      }),
    }),
  }),
  location: PropTypes.any,
};

export default HomePage;
