import React from 'react';
import t from '@unicat/i18n/Translate';
import { useStyles } from './styles';
import { anchors } from '@config/headerRoutes';
import clsx from 'clsx';
import categoriesInfo from '@config/WeCreate';
import WeCreateLink from '@unicat/WeCreate/WeCreateLink';

const WeCreate = () => {
  const classes = useStyles();

  return (
    <>
      {/** Cat Head */}
      <div className={classes.catHeadContainer}>
        <div className={classes.catHead} />
      </div>
      {/** We create Section */}
      <section id={anchors.WeCreate} className={classes.wrapper}>
        <div className={classes.content}>
          <h2 className={clsx([classes.title])}>{t('We Create')}</h2>
          {/** Categories */}
          <div className={classes.blocksWrapper}>
            {categoriesInfo?.map((category, i) => (
              <WeCreateLink category={category} key={category.title + i} />
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default WeCreate;
