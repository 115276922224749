import Img0 from '@assets/cakes/095_alice_wonderland/img_4470.webp';
import Img1 from '@assets/cakes/095_alice_wonderland/img_4476.webp';
import Img2 from '@assets/cakes/095_alice_wonderland/img_4482.webp';
import Img3 from '@assets/cakes/095_alice_wonderland/img_4491.webp';
import Img4 from '@assets/cakes/095_alice_wonderland/img_4530.webp';

import { validateOne } from '@config/Validators/cake.validator';
import data from './data.json';
// import t from '@unicat/i18n/Translate';

export default validateOne(
  Object.assign({}, data, {
    imgLink: [Img0, Img1, Img2, Img3, Img4],
  }),
);
