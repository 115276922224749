// import cake1 from '@assets/WeCreate/categories/KidsSpecialCake.png'; // 127x207, 46.8Kb
import cake1 from '@assets/WeCreate/categories/KidsSpecialCake.webp'; // 127x207, 8Kb
// import cake2 from '@assets/WeCreate/categories/FikaCakes.png'; // 162x179, 53.8kb
import cake2 from '@assets/WeCreate/categories/FikaCakes.webp'; // 162x179, 10kb
// import cake3 from '@assets/WeCreate/categories/CandyBars.png'; // 179x162, 48kb
import cake3 from '@assets/WeCreate/categories/CandyBars_v2.webp'; // 162x147, 9.4kb
// import cake4 from '@assets/WeCreate/categories/FikaSpecialCakes.png'; // 190x186, 51.3Kb
import cake4 from '@assets/WeCreate/categories/FikaSpecialCakes_v2.webp'; // 162x159, 9Kb
import { anchors } from '@config/headerRoutes';

const categoriesInfo = [
  {
    img: cake1,
    title: 'Kids Special \n Cake',
    link: `/#${anchors.Portfolio}`,
    alt: 'Kids Special Cake',
    width: 127,
    height: 207,
  },
  {
    img: cake2,
    title: 'Fika \n Cakes',
    alt: 'Fika Cakes',
    link: `/#${anchors.Portfolio}`,
    width: 162,
    height: 179,
  },
  {
    img: cake3,
    title: 'Candy \n Bars',
    alt: 'Candy Bars',
    link: `/#${anchors.Portfolio}`,
    width: 162,
    height: 147,
  },
  {
    img: cake4,
    title: 'Fika Special \n Cakes',
    alt: 'Fika Special Cakes',
    link: `/#${anchors.Portfolio}`,
    width: 162,
    height: 159,
  },
];

export default categoriesInfo;
export const homeBannerTitle = 'make unique cake';
