import Img0 from '@assets/cakes/053_frozen_candybar/img_1343.webp';
import Img1 from '@assets/cakes/053_frozen_candybar/img_1403.webp';
import Img2 from '@assets/cakes/053_frozen_candybar/img_1404.webp';
import Img3 from '@assets/cakes/053_frozen_candybar/img_1412.webp';
import Img4 from '@assets/cakes/053_frozen_candybar/img_1415.webp';
import Img5 from '@assets/cakes/053_frozen_candybar/img_1434.webp';
import Img6 from '@assets/cakes/053_frozen_candybar/img_1439.webp';

import { validateOne } from '@config/Validators/cake.validator';
import data from './data.json';
// import t from '@unicat/i18n/Translate';

export default validateOne(
  Object.assign({}, data, {
    imgLink: [Img0, Img1, Img2, Img3, Img4, Img5, Img6],
  }),
);
