import discount_banner from '@backgrounds/GetDiscount/img_pinterest.webp'; // 833x436, 35kb
import validate from '@config/Validators/bannercarousel.validator';

const slidesInfo = validate([
  {
    link: discount_banner,
    title: 'get discount',
    alt: 'Exchange Selection from Pinterest to 5% discount',
    shadowStyle: 'noShadow',
  },
  {
    link: discount_banner,
    title: 'get discount',
    alt: 'Exchange Selection from Pinterest to 5% discount',
    shadowStyle: 'noShadow',
  },
  {
    link: discount_banner,
    title: 'get discount',
    alt: 'Exchange Selection from Pinterest to 5% discount',
    shadowStyle: 'noShadow',
  },
]);

export default slidesInfo;
export const getDiscountTitle = 'get 5% discount';
