import { makeStyles } from '@material-ui/core/styles';
import bg from '@backgrounds/Ordering/delivery_bubble.svg';
import bg1 from '@backgrounds/Ordering/bubles_mobile.svg';

// up/down/between/only: 'xs' | 'sm' | 'md' | 'lg' | 'xl'

const bubbleRotate = '15deg';
const bubbleTransition = { transition: 'all 0.25s' };

export const useStyles = makeStyles((theme) => ({
  /** container */
  orderingProcess: {
    // minHeight: 500,
    color: theme.palette.purpleTextColor,
    backgroundColor: theme.palette.pinkBackgroundColor,
    paddingTop: 150,
  },
  /** Ordering */
  orderingWrapper: {
    display: 'flex',
    justifyContent: 'center',
    maxWidth: 1500,
    margin: 'auto',
    width: '100%',
  },
  pointsWrapper: {
    minWidth: '300px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    lineHeight: '1.55',
    width: '45%',
    [theme.breakpoints.down('xs')]: {
      width: '80%',
    },
  },
  block: {
    whiteSpace: 'pre-line',
    textAlign: 'left',
    display: 'grid',
    gridTemplateRows: '65px auto',
    gridTemplateColumns: '15% 241px',
    gridTemplateAreas: '"point title" "point text"',
  },
  gridBlock: {
    display: 'grid',
    margin: '0 auto',
    minWidth: 500,
    gridTemplateRows: '65px auto',
    gridTemplateColumns: '15% 241px',
    gridTemplateAreas: '"point title" "point text"',
    [theme.breakpoints.down('md')]: {
      margin: 'unset',
      gridTemplateRows: ' auto auto auto',
      gridTemplateColumns: 'auto',
      gridTemplateAreas: '"point" "title" "text"',
      minWidth: 100,
    },
    [theme.breakpoints.down('xs')]: {
      // minWidth: 400,
      gridTemplateRows: '60px auto',
      gridTemplateColumns: '25% 241px',
      gridTemplateAreas: '"point title" "point text"',
      marginBottom: '80px',
    },
  },
  /** backgrounds */
  catWrapper: {
    height: '100%',
    color: theme.palette.purple,
    fontSize: '1rem',
    alignSelf: 'center',
    [theme.breakpoints.down('sm')]: {
      paddingTop: 100,
    },
  },
  bubble: {
    ...bubbleTransition,
    [theme.breakpoints.down('md')]: {
      transform: `rotate(${bubbleRotate})`,
    },
  },
  bubbleText: {
    ...bubbleTransition,
    maxWidth: 151,
    position: 'absolute',
    margin: '0 auto',
    fontFamily: theme.typography.textsFontFamily,
    top: '35%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    [theme.breakpoints.down('md')]: {
      transform: `translate(-50%, -50%) rotate(-${bubbleRotate})`,
    },
    transformOrigin: 'center',
  },
  relative: {
    position: 'relative',
  },
  imageCat: {
    width: '100%',
    minWidth: '0',
    maxWidth: 762,
    objectFit: 'cover',
    objectPosition: 'right center',
  },
  imageCloud: {
    backgroundImage: `url(${bg})`,
    backgroundPosition: ' center',
    backgroundSize: '100% auto',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.down('sm')]: {
      backgroundSize: '150% auto',
      backgroundPosition: 'right 20% center',
    },
    [theme.breakpoints.down('xs')]: {
      backgroundSize: 'auto 100%',
      backgroundImage: `url(${bg1})`,
      backgroundPosition: 'left center',
    },
  },
  /** Delivery types */
  deliveryProcess: {
    position: 'relative',
    margin: '0 auto',
    width: '100%',
    maxWidth: '1061px',
    minWidth: '700px',
    minHeight: 500,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '&::after': {
      display: 'none',
    },
    [theme.breakpoints.down('md')]: {
      minWidth: '300px',
    },
    [theme.breakpoints.down('xs')]: {
      minHeight: 950,
    },
  },
  deliveryContainer: {
    // maxWidth: '80%',
    margin: 'auto',
    width: '100%',
    paddingTop: '4rem',
    [theme.breakpoints.down('xs')]: {
      marginLeft: '10%',
      maxWidth: '80%',
    },
  },
  deliveryWrapper: {
    display: 'flex',
    width: '100%',
    padding: '0 50px',
    [theme.breakpoints.down('sm')]: {
      padding: '0 10px',
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  deliveryBlock: {
    width: 'inherit',
    whiteSpace: 'pre-line',
    textAlign: 'left',
    // maxWidth: 241,
    margin: '0 auto',
    fontSize: '16px',
    [theme.breakpoints.down('xl')]: {
      paddingRight: '10px', // columns distance
      paddingLeft: '10px', // columns distance
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: '30px',
    },
  },
  limitForTitle: {
    [theme.breakpoints.down('xs')]: {
      margin: 'auto auto 0 auto',
      maxWidth: 200,
    },
  },
  /** Texts */
  sectionTitle: {
    ...theme.typography.h2PurpleText,
    [theme.breakpoints.down('xs')]: {
      marginBottom: '45px',
      marginLeft: '50px',
      marginRight: '50px',
      textAlign: 'left',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '5px 50px',
    },
  },
  number: {
    fontFamily: theme.typography.patricHand,
    gridArea: 'point',
    fontSize: '60px',
    // marginBottom: '-10px',
    [theme.breakpoints.down('xs')]: {
      // marginBottom: 0,
    },
  },
  title: {
    ...theme.typography.titlePurpleText,
    gridArea: 'title',
    margin: 'auto auto 0 0',
    paddingBottom: '5px',
    [theme.breakpoints.down('md')]: {
      margin: 0,
    },
    [theme.breakpoints.down('xs')]: {
      margin: 'auto auto 0 0',
    },
  },
  text: {
    ...theme.typography.normalGrayText,
    gridArea: 'text',
    [theme.breakpoints.down('md')]: {
      maxWidth: 230,
    },
  },
  debug: {
    // up/down/between/only: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
    [theme.breakpoints.down('xl')]: {
      outline: '1px solid red',
    },
    [theme.breakpoints.down('lg')]: {
      outline: '1px solid green',
    },
    [theme.breakpoints.down('md')]: {
      outline: '1px solid blue',
    },
    [theme.breakpoints.down('sm')]: {
      outline: '1px solid yellow',
    },
    [theme.breakpoints.down('xs')]: {
      outline: '1px solid black',
    },
  },
}));
