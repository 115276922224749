import React from 'react';
import { cakeInfoPropTypes } from '@config/Validators/cake.validator';

import { ReactComponent as Price } from '@assets/icons/DetailsBar/price.svg';
import { ReactComponent as PieceOfCake } from '@assets/icons/DetailsBar/piece-of-cake-with-c.svg';
import { ReactComponent as Person } from '@assets/icons/DetailsBar/person.svg';
import { useStyles } from '@unicat/DetailsBar/styles';

const DetailParams = ({ cost, numOfPeople, numOfPieces }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.params}>
        {/* price */}
        <span>
          <Price />
          {cost}
        </span>
        {/* pieces */}
        <span>
          <PieceOfCake />
          {numOfPieces}
        </span>
        {/* persons */}
        <span>
          <Person />
          {numOfPeople}
        </span>
      </div>
    </>
  );
};

const { cost, numOfPeople, numOfPieces } = cakeInfoPropTypes;
DetailParams.propTypes = {
  cost,
  numOfPeople,
  numOfPieces,
};

export default DetailParams;
