import makeStyles from '@material-ui/core/styles/makeStyles';
import bgShadow from '@backgrounds/HomeBanner/bg_cake_shadow.svg'; // 687x132, 542B

const sliderOverlap = 200;

// 'xs' | 'sm' | 'md' | 'lg' | 'xl'

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
    maxWidth: 1000,
    margin: '0 auto',
    '&:focus, &:active, & *:focus, & *:active': {
      outline: 'none !important',
      userSelect: 'none !important',
    },
    transition: 'all 0.25s',
    [theme.breakpoints.down('md')]: {
      maxWidth: 700,
      margin: `-${sliderOverlap}px 0 0 auto`,
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      margin: '0 auto',
    },
  },
  slider: {},
  slide: {
    paddingBottom: '1% !important',
  },
  imageShadow: {
    backgroundImage: `url(${bgShadow})`,
    backgroundPosition: 'bottom center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
  },
  imageSlide: {
    maxWidth: '100%',
    height: 'auto',
    width: 'unset',
    margin: '0 auto',
    marginTop: 20,
    [theme.breakpoints.down('md')]: {
      paddingLeft: '5%',
      paddingRight: '5%',
    },
  },
  noShadow: {
    backgroundImage: 'unset',
    backgroundPosition: 'unset',
    backgroundRepeat: 'unset',
  },
  debug: {
    border: '1px black solid',
  },
}));
