import Img0 from '@assets/cakes/121_winter_silver_tree/img_5164.webp';

import { validateOne } from '@config/Validators/cake.validator';
import data from './data.json';
// import t from '@unicat/i18n/Translate';

export default validateOne(
  Object.assign({}, data, {
    imgLink: [Img0],
  }),
);
