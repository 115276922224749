import { checkPropTypes, number, string } from 'prop-types';

/** Validator for BannerCarousel.slides data object */
export const propTypes = {
  link: string.isRequired,
  title: string.isRequired,
  alt: string.isRequired,
  width: number,
  height: number,
  shadowStyle: string,
  imageStyle: string,
};

export const validateOne = (data, element) => {
  checkPropTypes(propTypes, data, element, 'HomeBanner');
  return data;
};

/** Validate array of elements. */
const validate = (array) => {
  if (!Array.isArray(array)) throw Error('Expected array of objects!');

  for (let i = 0; i < array.length; i++) {
    validateOne(array[i], `element_${i}`);
  }

  return array;
};

export default validate;
