import { makeStyles } from '@material-ui/core/styles';
import frameImg from '@backgrounds/Testimonials/bg_photo_frame.svg'; // 368x252, 16Kb
import ratingImg from '@backgrounds/Testimonials/bg_rating.svg'; // 105x105, 1.5kb

export const useStyles = makeStyles((theme) => ({
  slideContainer: {
    alignItems: 'center',
    // min-height adjusts to carousel height
    minHeight: 480,
  },
  container: {
    maxWidth: 500,
    margin: '0 auto',
    textAlign: 'center',
  },
  frame: {
    // border: '1px blue solid',
    margin: '0 auto',
    backgroundImage: `url(${frameImg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    width: 368,
    height: 252,
    padding: 34,
  },
  photo: {
    borderRadius: 10,
  },
  rating: {
    backgroundImage: `url(${ratingImg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'bottom right',
    width: 105,
    height: 105,
    position: 'absolute',
    bottom: 'calc(63% - 105px)',
    right: 'calc(37% - 105px)',
    fontFamily: theme.typography.patricHand,
    color: 'white',
    paddingTop: 6,
    paddingLeft: 20,
    fontSize: '59px',
  },
  story: {
    ...theme.typography.normalGrayText,
    marginTop: 40,
  },
}));
