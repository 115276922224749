import Img0 from '@assets/cakes/015_happy_bithday_diana_5/img_9830.webp';
import Img1 from '@assets/cakes/015_happy_bithday_diana_5/img_9832.webp';
import Img2 from '@assets/cakes/015_happy_bithday_diana_5/img_9836.webp';
import Img3 from '@assets/cakes/015_happy_bithday_diana_5/img_9838.webp';
import Img4 from '@assets/cakes/015_happy_bithday_diana_5/img_9840.webp';
import Img5 from '@assets/cakes/015_happy_bithday_diana_5/img_9846.webp';
import Img6 from '@assets/cakes/015_happy_bithday_diana_5/img_9847.webp';
import Img7 from '@assets/cakes/015_happy_bithday_diana_5/img_9849.webp';

import { validateOne } from '@config/Validators/cake.validator';
import data from './data.json';
// import t from '@unicat/i18n/Translate';

export default validateOne(
  Object.assign({}, data, {
    imgLink: [Img0, Img1, Img2, Img3, Img4, Img5, Img6, Img7],
  }),
);
