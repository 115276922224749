import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useStyles } from './styles';
import InstaCard from '@unicat/InstaFeed/InstaCard/InstaCard';

const query = graphql`
  query fetchInstagramPostsInlined {
    allInstaNode(limit: 9, sort: { fields: timestamp, order: DESC }) {
      edges {
        node {
          id
          username
          likes
          comments
          caption
          timestamp
          localFile {
            childImageSharp {
              fluid(maxWidth: 600, maxHeight: 600) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  }
`;

const InstagramGrid = ({ card = InstaCard }) => {
  const classes = useStyles();
  const data = useStaticQuery(query);

  const Child = card;

  return (
    data?.allInstaNode.edges && (
      <div className={classes.grid}>
        {data?.allInstaNode.edges.map(({ node }) => {
          return <Child key={node.id} {...node} />;
        })}
      </div>
    )
  );
};

export default InstagramGrid;
