import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { propTypes as CakesPropTypes } from '@config/Validators/cake.validator';
import t from '@unicat/i18n/Translate';

import { useStyles } from './styles';
import CardWrapper from '@unicat/CakeCard/CardWrapper';
import DetailsBar from '@unicat/DetailsBar/DetailsBar';
import ImageWrapper from '@unicat/UI/ImageWrapper';
import CakeCtaButton from '@unicat/UI/CakeCtaButton';

const CakeCard = ({ image: { id, cakeInfo, imgLink, isActive }, hovered, interval }) => {
  const styles = useStyles();
  const description = `${id}`;

  return (
    <>
      <CardWrapper images={imgLink} interval={interval}>
        {/** get dimensions for next block by getting image params */}
        <img loading={'lazy'} className={clsx([styles.origin, styles.image])} src={imgLink?.[0]} alt={t(description)} />
        <ImageWrapper src={imgLink?.[0]} alt={id} hovered={hovered} />
        <DetailsBar hovered={hovered ?? false} cakeInfo={cakeInfo} id={id} />
        {isActive ? <CakeCtaButton hovered={hovered} action={() => {}} /> : <></>}
      </CardWrapper>
    </>
  );
};

CakeCard.defaultProps = {
  hovered: false,
  interval: 1500,
};

const imagePropTypes = { ...CakesPropTypes };
CakeCard.propTypes = {
  image: PropTypes.shape(imagePropTypes).isRequired,
  hovered: PropTypes.bool,
  interval: PropTypes.number,
};

export default CakeCard;
