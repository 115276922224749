import React from 'react';
import clsx from 'clsx';
import t from '@unicat/i18n/Translate';
import data from '@config/OrderingProcess/index.json';
import { useStyles } from './styles';
import { Hidden } from '@material-ui/core';
import CatBox from '@unicat/Ordering/CatBox';
import TextLinkParser from '@unicat/TextLinkParcer';
import { anchors } from '@config/headerRoutes';

const Ordering = () => {
  const classes = useStyles();

  return (
    <section className={clsx([classes.orderingProcess])}>
      <h2 id={anchors.Ordering} className={clsx([classes.sectionTitle])}>
        {t('Ordering Process')}
      </h2>
      <div className={clsx([classes.orderingWrapper])}>
        <Hidden smDown>
          <CatBox />
        </Hidden>
        <div className={clsx([classes.pointsWrapper])}>
          {data.ordering.map((item) => (
            <div key={item.point} className={clsx(classes.block, classes.gridBlock)}>
              <div className={clsx([classes.number])}>{t(item.point)}</div>
              <div className={clsx([classes.title])}>{<TextLinkParser string={t(item.title)} />}</div>
              <div className={clsx([classes.text])}>{<TextLinkParser string={t(item.text)} />}</div>
            </div>
          ))}
        </div>
      </div>
      <div className={clsx([classes.deliveryProcess, classes.imageCloud])}>
        <div className={clsx([classes.deliveryContainer])}>
          <h3 className={clsx(classes.sectionTitle, classes.limitForTitle)}>{t('3 Types Of Delivery')}</h3>
          <div className={clsx([classes.deliveryWrapper])}>
            {data.delivery.map((item) => (
              <div key={item.point} className={clsx([classes.deliveryBlock])}>
                <div className={clsx([classes.title])}>{<TextLinkParser string={t(item.title)} />}</div>
                <div className={clsx([classes.text])}>{<TextLinkParser string={t(item.text)} />}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Hidden only={['md', 'lg', 'xl']}>
        <CatBox />
      </Hidden>
    </section>
  );
};

export default Ordering;
