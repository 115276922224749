import Img0 from '@assets/cakes/102_bond_50/img_4687.webp';
import Img1 from '@assets/cakes/102_bond_50/img_4693.webp';
import Img2 from '@assets/cakes/102_bond_50/img_4717.webp';

import { validateOne } from '@config/Validators/cake.validator';
import data from './data.json';
// import t from '@unicat/i18n/Translate';

export default validateOne(
  Object.assign({}, data, {
    imgLink: [Img0, Img1, Img2],
  }),
);
