import React from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './styles';
import LocalizedLink from '@unicat/i18n/LocalizedLink';
import t from '@unicat/i18n/Translate';

const WeCreateLink = ({ category }) => {
  const classes = useStyles();

  return (
    <>
      <div key={category.title} className={classes.block}>
        <div className={classes.imgWrapper}>
          <LocalizedLink to={category.link}>
            <img
              src={category.img}
              className={classes.img}
              alt={t(category.alt)}
              width={category.width}
              height={category.height}
            />
          </LocalizedLink>
        </div>
        <div className={classes.categoryTitle}>{category.title}</div>
      </div>
    </>
  );
};

WeCreateLink.propTypes = {
  category: PropTypes.shape({
    img: PropTypes.string,
    title: PropTypes.string,
    link: PropTypes.string,
    alt: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
  }).isRequired,
};

export default WeCreateLink;
