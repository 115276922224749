import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import intersectionWith from 'lodash/intersectionWith';
import CakeCard from '@unicat/CakeCard';
import { interval, pagination } from '@config/Portfolio/index.json';
import { default as gallery } from '@config/Portfolio/cakes';
import GalleryListItems from '@unicat/UI/GalleryListItems';
import { useStyles } from './styles';
import t from '@unicat/i18n/Translate';
import hash from 'object-hash';

// filter gallery items
// returns full items array if filters are not selected
// memoization gallery
// memoization sorted gallery
const Gallery = ({ selected = [] }) => {
  const classes = useStyles();

  const [page, setPage] = useState(1); // shown # of pages
  const [visible, setVisible] = useState(true); // are buttons visible
  const allCakes = useMemo(() => [...gallery], [gallery]);
  let totalCakes = allCakes.length;
  const filteredCakes = useMemo(() => {
    const selectedCakes = allCakes?.filter((cake) => {
      return !selected.length || intersectionWith(cake.tags, selected, (o, id) => o === id).length > 0;
    });
    totalCakes = selectedCakes.length;
    return pagination && page ? selectedCakes.slice(0, pagination * page) : selectedCakes;
  }, [allCakes, selected, pagination, page]);

  useEffect(() => {
    if (allCakes.length === filteredCakes.length) {
      setVisible(false);
    }
  }, [allCakes, filteredCakes]);

  const loadMore = () => setPage((prevPage) => ++prevPage);
  const loadAll = () => setPage((prevPage) => (prevPage = totalCakes / pagination));

  return (
    <>
      <GalleryListItems>
        {map(filteredCakes, (x) => {
          const hashKey = x.id || hash(x);
          return <CakeCard image={x} key={hashKey} interval={interval} hovered={false} />;
        })}
      </GalleryListItems>
      {visible && (
        <>
          <button className={classes.loadMore} onClick={loadMore}>
            {t('Load more')}
          </button>
          &nbsp;
          <button className={classes.loadMore} onClick={loadAll}>
            {t('Show All')} ({`${totalCakes}`})
          </button>
        </>
      )}
    </>
  );
};

Gallery.propTypes = {
  selected: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Gallery;
