import Img0 from '@assets/cakes/011_easter_bread/img_9621.webp';
import Img1 from '@assets/cakes/011_easter_bread/img_9623.webp';
import Img2 from '@assets/cakes/011_easter_bread/img_9628.webp';
import Img3 from '@assets/cakes/011_easter_bread/img_9633.webp';
import Img4 from '@assets/cakes/011_easter_bread/img_9636.webp';
import Img5 from '@assets/cakes/011_easter_bread/img_9644.webp';
import Img6 from '@assets/cakes/011_easter_bread/img_9646.webp';

import { validateOne } from '@config/Validators/cake.validator';
import data from './data.json';
// import t from '@unicat/i18n/Translate';

export default validateOne(
  Object.assign({}, data, {
    imgLink: [Img0, Img1, Img2, Img3, Img4, Img5, Img6],
  }),
);
