import Img0 from '@assets/cakes/057_unicor_sofia/img_1779.webp';
import Img1 from '@assets/cakes/057_unicor_sofia/img_1797.webp';

import { validateOne } from '@config/Validators/cake.validator';
import data from './data.json';
// import t from '@unicat/i18n/Translate';

export default validateOne(
  Object.assign({}, data, {
    imgLink: [Img0, Img1],
  }),
);
