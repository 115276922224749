import { makeStyles } from '@material-ui/core/styles';
import bgSvg from '@backgrounds/GetDiscount/bg_getdiscount.svg';

// overlap backgrounds
const overlap = 300;

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    minHeight: 1000,
    paddingTop: overlap,
    backgroundImage: `url(${bgSvg})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    [theme.breakpoints.down('xs')]: {
      paddingTop: '100px',
      paddingBottom: '100px',
      minHeight: 500,
    },
  },
  inner: {
    display: 'flex',
    maxWidth: 1600,
    margin: '0 auto',
    padding: '0 40px',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
    },
  },
  discount: {
    textAlign: 'left',
    paddingBottom: 50,
    zIndex: 2,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 400,
      margin: '0 auto',
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '90%',
    },
  },
  uniqueTitle: {
    fontSize: '5.5rem',
    fontFamily: theme.typography.textsFontFamily,
    color: theme.palette.white,
    minWidth: 500,
    maxWidth: 550,
    width: '100%',
    textTransform: 'uppercase',
    textAlign: 'left',
    marginBottom: '0.5em',
    [theme.breakpoints.down('sm')]: {
      minWidth: 'unset',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '3.5rem',
    },
    lineHeight: 0.9,
    '& span:nth-child(2)': {
      fontFamily: theme.typography.lemonBird,
      color: theme.palette.yellow,
      fontWeight: 400,
      lineHeight: 'inherit',
    },
  },
  text: {
    color: theme.palette.white,
    maxWidth: 241,
    fontSize: 16,
  },
}));
