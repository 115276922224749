import React from 'react';
import t from '@unicat/i18n/Translate';
import { useStyles } from './styles';
import { anchors } from '@config/headerRoutes';
import GalleryFilter from '@unicat/Portfolio/GalleryFilter';

const Portfolio = () => {
  const classes = useStyles();

  return (
    <section id={anchors.Portfolio} className={classes.wrapper}>
      <h3 className={classes.title}>{t('Portfolio')}</h3>
      <div className={classes.content}>
        <GalleryFilter />
      </div>
    </section>
  );
};

export default Portfolio;
