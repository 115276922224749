import Img0 from '@assets/cakes/077_equestia_girls/img_2275.webp';
import Img1 from '@assets/cakes/077_equestia_girls/img_2314.webp';
import Img2 from '@assets/cakes/077_equestia_girls/img_2317.webp';
import Img3 from '@assets/cakes/077_equestia_girls/img_2355.webp';
import Img4 from '@assets/cakes/077_equestia_girls/img_2358.webp';

import { validateOne } from '@config/Validators/cake.validator';
import data from './data.json';
// import t from '@unicat/i18n/Translate';

export default validateOne(
  Object.assign({}, data, {
    imgLink: [Img0, Img1, Img2, Img3, Img4],
  }),
);
