import { makeStyles } from '@material-ui/core/styles';

import Back from '@assets/icons/button.svg';

export const useStyles = makeStyles((theme) => ({
  floatOverlap: {
    position: 'absolute',
    // border: '1px black solid',
    height: 500,
    width: '100%',
    zIndex: 1000,
    display: 'block',
  },
  floatingBut: {
    backgroundImage: `url(${Back})`,
    position: 'sticky',
    display: 'flex',
    justifyContent: 'center',
    backgroundPosition: 'center',
    backgroundSize: '100% 100%',
    bottom: 40,
    padding: 3,
    marginRight: '5%',
    marginLeft: 'auto',
    width: 120,
    height: 120,
    fontSize: 16,
    transition: '0.4s',
    zIndex: 99,
    '&:hover': {
      transform: 'scale(1.15)',
      cursor: 'pointer',
      // opacity: 0.8,
      transition: '0.4s',
    },
    fallbacks: [{ /* Safari */ position: '-webkit-sticky' }],
  },
  text: {
    ...theme.typography.actionPurpleText,
    margin: 'auto 0',
    maxWidth: '80%',
    textAlign: 'center',
  },
}));
