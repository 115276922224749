import { makeStyles } from '@material-ui/core/styles';

const slideBtns = {
  position: 'absolute',
  background: 'transparent',
  border: 0,
  zIndex: 10,
  bottom: '50%',
  transform: 'translateY(50%)',
  borderRadius: '100%',
  padding: 0,

  // accessibility without square outline
  '&:focus': {
    outline: 'none',
    boxShadow: '0 0 7px 3.6px rgba(0,0,0,0.5)',
    background: 'rgba(0,0,0,0.5)',
  },
};

const slideBtnsSm = {
  zIndex: 0,
  transform: 'unset',
  position: 'relative',
  '&:focus': {
    boxShadow: 'unset',
    background: 'unset',
  },
};

export const useStyles = makeStyles((theme) => ({
  btnPrev: {
    ...slideBtns,
    ...theme.buttonOutline,
    left: -50,
    [theme.breakpoints.down('sm')]: {
      ...slideBtnsSm,
    },
  },
  btnNext: {
    ...slideBtns,
    ...theme.buttonOutline,
    right: -50,
    [theme.breakpoints.down('sm')]: {
      ...slideBtnsSm,
    },
  },
}));
