import Img0 from '@assets/cakes/101_frozen_2/img_4669.webp';
import Img1 from '@assets/cakes/101_frozen_2/img_4678.webp';

import { validateOne } from '@config/Validators/cake.validator';
import data from './data.json';
// import t from '@unicat/i18n/Translate';

export default validateOne(
  Object.assign({}, data, {
    imgLink: [Img0, Img1],
  }),
);
