import React from 'react';
import { Slide } from 'pure-react-carousel';
import clsx from 'clsx';
import includes from 'lodash/includes';
import PropTypes from 'prop-types';

import { useStyles } from '../styles';

const SlideContainer = ({ item: { id, name }, click, selected, index }) => {
  const classes = useStyles();
  return (
    <Slide index={index}>
      <div
        key={id}
        onKeyPress={click(id)}
        onClick={click(id)}
        role="button"
        tabIndex="0"
        className={clsx(classes.filter)}
        style={{ backgroundColor: includes(selected, id) ? '#fef0f1' : 'transparent' }}>
        {name}
      </div>
    </Slide>
  );
};

SlideContainer.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    isActive: PropTypes.bool,
  }).isRequired,
  click: PropTypes.func.isRequired,
  selected: PropTypes.arrayOf(PropTypes.string).isRequired,
  index: PropTypes.number.isRequired,
};

export default SlideContainer;
