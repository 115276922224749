import { makeStyles } from '@material-ui/core/styles';
import bgSvg from '@backgrounds/AboutUs/bg_about_us.svg'; // 1438x882, 864B
import bgYellow from '@backgrounds/AboutUs/bg_yellow.svg'; // 659x488, 354B

const overlap = 300;

// 'xs' | 'sm' | 'md' | 'lg' | 'xl'

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: '0 30px',
    backgroundImage: `url(${bgSvg})`,
    minHeight: 916,
    paddingBottom: overlap / 3,
    marginTop: -overlap,
    backgroundSize: 'cover',
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    overflowX: 'hidden',
    [theme.breakpoints.down('sm')]: {
      minHeight: 950,
      paddingTop: 'unset',
      paddingBottom: 'unset',
    },
  },
  aboutUs: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    maxWidth: 1200,
    lineHeight: 1.55,
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      marginTop: 'unset',
    },
  },
  title: {
    ...theme.typography.h2PurpleText,
    color: theme.palette.white,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      marginTop: '-8%',
      alignItems: 'flex-start',
      paddingLeft: '2%',
    },
  },
  imgFamily: {
    width: '50%',
    maxWidth: 659,
    maxHeight: 488,
    padding: '44px 10px', // 654x400
    backgroundImage: `url(${bgYellow})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'top left',
    [theme.breakpoints.down('md')]: {
      padding: '52px 25px',
    },
    [theme.breakpoints.down('sm')]: {
      order: '-2',
      alignSelf: 'flex-end',
      minWidth: 483,
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: 400,
    },
  },
  content: {
    width: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 30px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: 0,
    },
  },

  text: {
    ...theme.typography.normalWhiteText,
    width: '100%',
    maxWidth: 361,
    margin: 'auto',
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 411,
      marginLeft: 0,
    },
  },
  imgWrapper: {
    [theme.breakpoints.down('sm')]: {
      objectFit: 'cover',
      objectPosition: 'center',
    },
  },
}));
