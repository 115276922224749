import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  button: {
    position: 'absolute',
    ...theme.typography.actionPurpleText,
    ...theme.actionLinkButton,
    opacity: 0,
    top: '50%',
    left: '50%',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    zIndex: -2,
    transform: 'translate(-50%, -50%)',
    transition: 'opacity 0.35s',
    ...theme.buttonOutline,
  },
  hovered: {
    opacity: 1,
    zIndex: 99,
    [theme.breakpoints.up('md')]: {
      '&:hover': {
        transform: 'translate(-50%, -50%) scale(0.95)',
      },
    },
  },
}));
