import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    minHeight: 800,
    backgroundColor: 'white',
    [theme.breakpoints.down('sm')]: {
      minHeight: 0,
    },
  },
  content: {
    minHeight: 600,
    textAlign: 'center',
    maxWidth: 1440,
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      minHeight: 200,
    },
  },
  title: {
    ...theme.typography.h2PurpleText,
    margin: 0,
    padding: '2em',
    [theme.breakpoints.down('sm')]: {
      padding: '0.5em',
    },
  },
  filter: {
    ...theme.typography.normalPurpleText,
    fontSize: '1.25rem',
    padding: '.6rem 1.375rem',
    borderRadius: '1.25rem',
    ...theme.buttonOutline,
    margin: '0 0.5rem',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('sm')]: {
      minWidth: 150,
      maxWidth: 400,
      margin: 'auto',
    },
  },
}));
